import { Component, Input } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { AuthService } from '@global/services/auth/auth.service';
import { VerifyEmailRequest } from '@global/services/auth/verify-email.model';
import { NotificationService } from '@global/services/notification/notification.service';
import { map, takeUntil, timer } from 'rxjs';
import {AtomValidators} from "@core-shared/directives/validator/custom-validators";
import { environment } from '@environments/environment';

@Component({
  selector: 'atom-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  @Input() userType!: string;
  @Input() id!: string;
  @Input() code!: string;
  @Input() organizationId!: string;

  userLoginForm!: FormGroup;
  email!: FormControl;
  password!: FormControl;
  confirmPassword!: FormControl;
  showForm = false;
  showPassword = false;

  isSending = false;
  isRedirecting = false;

  redirect = 5000;
  countdown = 5;

  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private notify: NotificationService,
    private router: Router) { }

  ngOnInit() {
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(64),
      AtomValidators.validPassword()
    ]);
    this.confirmPassword = new FormControl('', [
      Validators.required,
      AtomValidators.matchPasswordValidator(this.password)
    ]);


    const passwords = this.fb.group({
      password: this.password,
      confirmPassword: this.confirmPassword
    });

    this.userLoginForm = this.fb.group({
      email: this.email,
      passwords: passwords
    });

    // Handle arriving at this page without query parameters present
    if (this.id && this.code && this.organizationId) {
      this.showForm = true;
    }
  }

  onSubmit() {
    if (!this.userType || !this.id || !this.code || !this.organizationId) {
      this.notify.showError("Error", `Missing user type, code, id, or organization id`, 5000);
      return new Promise<any>((_resolve, reject) => reject());
    }

    const request: VerifyEmailRequest = {
      id: this.id,
      secretCode: this.code,
      organizationId: this.organizationId,
      email: this.userLoginForm.value['email'],
      password: this.userLoginForm.value['passwords'].password
    }

    this.isSending = true;
    this.showForm = false;

    if (this.userType == "driver"){//userType driver
      return this.auth.verifyDriverEmail(request).then(() => {
        this.notify.showSuccess(`Success`, "Your email has been verified", 5000);
        this.isSending = false;
        this.isRedirecting = true;
        timer(0, 1000).pipe(
          takeUntil(timer(this.redirect + 1000)),
          map((x) => {
            this.countdown = (this.redirect / 1000) - x;
            return this.countdown;
          })
        ).subscribe({
          complete: () => {
            window.location.href = environment.driverAppUrl;
          }
        })
      }).catch(() => {
        this.showForm = true;
        this.notify.showError(`Error`, `Invalid id, email, code, or code is expired`, 5000);
      }).finally(() => {
        this.isSending = false;
      })
    }
    else {//userType insight
      return this.auth.verifyUserEmail(request).then(() => {
        this.notify.showSuccess(`Success`, "Your email has been verified", 5000);
        this.isSending = false;
        this.isRedirecting = true;
        timer(0, 1000).pipe(
          takeUntil(timer(this.redirect + 1000)),
          map((x) => {
            this.countdown = (this.redirect / 1000) - x;
            return this.countdown;
          })
        ).subscribe({
          complete: async () => {
            await this.router.navigateByUrl("/");
          }
        })
      }).catch(() => {
        this.showForm = true;
        this.notify.showError(`Error`, `Invalid id, email, code, or code is expired`, 5000);
      }).finally(() => {
        this.isSending = false;
      })
    }
  }
}
