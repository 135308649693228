<div class="flex absolute button-bar-container right-1rem bottom-0 left-16rem">
  <div class="flex w-full justify-content-between button-bar button-bar-active">
    <p-button id="discard-changes"
              class="outline-button"
              [label]="declineButton"
              (onClick)="declineClicked()"/>
    <p-button [label]="acceptButton"
              (onClick)="acceptClicked()"/>
  </div>
</div>
