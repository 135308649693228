import {Component, Input} from '@angular/core';

@Component({
  selector: 'atom-widget-error',
  templateUrl: './widget-error.component.html',
  styleUrls: ['./widget-error.component.scss']
})
export class WidgetErrorComponent {
  @Input() widgetTitle!: string;
  @Input() customErrorMessage = false;
  @Input() customHeaders: string[] = [];
  @Input() customContent: string[] = [];
}
