import { Component, Input, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { WidgetState } from "../widget-container/widget-state";
import { HighchartsChartComponent } from "highcharts-angular";
import { PieChartData } from '@core-shared/models/chart.models';

@Component({
  selector: 'atom-charger-status',
  templateUrl: './charger-status.component.html',
  styleUrls: ['./charger-status.component.scss']
})
export class ChargerStatusComponent {
  @ViewChild('chart') chart!: HighchartsChartComponent;

  @Input() chargerStatusState: WidgetState = WidgetState.Good;

  chargerStatusHeaderText: string = 'Charger Status';
  statusFooterText: string = 'View Details';

  @Input() headerRightText!: string;

  @Input()
  get chargerStatus(): PieChartData[] {
    return this._chargerStatus;
  };

  set chargerStatus(data: PieChartData[]) {
    if (data && data.length > 0) {
      this._chargerStatus = data;

      if (this.chartOptions) {
        this.updateSeriesOptions();
      } else {
        this.initChartOptions();
      }

      this.updateChargerCount();
    }
  }

  private _chargerStatus: PieChartData[] = [];

  totalChargerCount: number = 0;

  highcharts: typeof Highcharts = Highcharts;

  chartOptions!: Highcharts.Options
  chartUpdate: boolean = false;

  findGraphQuadrant(x: number, y: number): number {
    let quadrant = 0;

    if (y >= 75) {
      quadrant = 3;
    } else {
      quadrant = 1;
    }

    if (x >= 75) {
      quadrant = quadrant + 1
    }

    return quadrant
  }

  navToTable() {
    let table = document.getElementById('table')
    table?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  initChartOptions() {
    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: 0,
        plotShadow: false,
        margin: 0,
        backgroundColor: undefined,
      },
      title: {
        text: undefined
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          }
        },
        pie: {
          zIndex: 2,
          animation: true,
          dataLabels: {
            enabled: false
          }
        }
      },
      xAxis: {
        crosshair: false
      },
      tooltip: {
        positioner: (labelWidth, labelHeight, point: Highcharts.TooltipPositionerPointObject) => {
          let q = this.findGraphQuadrant(point.plotX, point.plotY)
          let x = 0
          let y = 0

          switch (q) {
            case 1:
              x = point.plotX - (labelWidth);
              y = point.plotY - (labelHeight);
              break;
            case 2:
              x = point.plotX;
              y = point.plotY - (labelHeight);
              break;
            case 3:
              x = point.plotX - (labelWidth);
              y = point.plotY;
              break;
            case 4:
              x = point.plotX;
              y = point.plotY;
              break;
          }

          return { x, y }
        },
        shape: "square",
        animation: false,
        outside: true,
        useHTML: true,
        headerFormat: '<div><span style="color: {point.color}">\u25CF</span> {point.key}</div>',
        backgroundColor: "var(--map-popup-color,#ffffff)",
        pointFormat: '<div style="margin-top: 10px"><span>{point.y} Chargers</span></div>',
        style: {
          color: "#ffffff",
          fontWeight: "normal",
          fontFamily: "Inter",
          fontSize: ".85rem"
        }
      },
      series: [{
        type: 'pie',
        enableMouseTracking: true,
        borderRadius: 0,
        borderWidth: 0,
        zIndex: 3,
        innerSize: 80,
        stickyTracking: false,
        tooltip: {
          followPointer: false,
        },
        data: this.chargerStatus
      }]
    };
  }

  updateSeriesOptions() {
    if (this.chartOptions?.series) {
      this.chartOptions.series[0] = {
        type: 'pie',
        enableMouseTracking: true,
        borderRadius: 0,
        borderWidth: 0,
        zIndex: 3,
        innerSize: 80,
        stickyTracking: false,
        tooltip: {
          followPointer: false,
        },
        data: this.chargerStatus
      }

      this.chartUpdate = true;
    }
  }

  updateChargerCount() {
    this.totalChargerCount = 0;

    if (this.chargerStatus) {
      this.chargerStatus.forEach(item => {
        this.totalChargerCount += item.y;
      })
    }
  }
}
