import {TemplateRef} from "@angular/core";

export enum AtomDropdownStyle {
  CountryCode
}

export interface AtomFormDropdownOpts {
  /**
   * label
   *
   * The name of the field in the dropdown options to be displayed
   * */
  label?: string;
  /**
   * value
   *
   * The variable name of the value used in the dropdown options
   * */
  value?: string;
  /**
   * placeholder
   *
   * The placeholder text for the dropdown when no option is selected
   * */
  placeholder?: string;
  /**
   * itemTemplate
   *
   * Template override for the display of each dropdown item.
   */
  itemTemplate?: TemplateRef<any>;

  /**
   * selectedItemTemplate
   *
   * Template override for the display of the selected item in the dropdown.
   */
  selectedItemTemplate?: TemplateRef<any>;

  /**
   * dropdownStyle
   *
   * The style of the dropdown to be displayed
   */
  dropdownStyle?: AtomDropdownStyle;
}

