<ng-container *ngIf="!buttonVisible || !buttonText">
  <div class="section-header-text-container">
    <div class="section-header-text">
      {{subsectionTitle}}
    </div>
  </div>
  <div *ngIf="subsectionBody" class="section-header-text-container">
    <div class="section-body-text">
      {{subsectionBody}}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="buttonVisible && buttonText">
  <div class="flex w-full flex-grow-1 flex-column gap-4">
    <div class="section-header-text-container ">
      <div class="flex justify-content-center align-items-center section-header-text-height">
        <span class=" configuration-header-text">
          {{subsectionTitle}}
        </span>
      </div>
      <p-button *ngIf="buttonVisible" class="outline-button" [label]="buttonText" (onClick)="buttonClick()">
        <div *ngIf="buttonIcon" class="material-symbols-sharp">{{buttonIcon}}</div>
      </p-button>
    </div>
    <div *ngIf="subsectionBody" class="section-header-text-container">
      <div class="section-body-text">
        {{subsectionBody}}
      </div>
    </div>
  </div>
</ng-container>
