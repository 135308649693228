import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {EntryPoint} from "@global/models/user-data.model";
import {catchError, map, Observable} from "rxjs";
import {AuthService} from "@global/services/auth/auth.service";

export const entrypointResolver: ResolveFn<EntryPoint> = (route, state): Observable<EntryPoint> => {
  const http = inject(HttpClient);
  const auth = inject(AuthService);
  return http.get<EntryPoint>(`${environment.apiEndpoint}/auth/entrypoint`).pipe(
    map(n => {
      return n
    }),
    catchError((err, caught) => {
      auth.logout()

      return caught
  }))
};
