import { WidgetState } from "@core-shared/components/widget-container/widget-state";
import {Table, TableFilterEvent, TablePageEvent} from "primeng/table";

export enum SupportedPadding {
  Padding120,
  Padding90
}

export enum TemplateType {
  Good,
  Info,
  Warning,
  Error
}

export declare interface AtomTableBase {
  tableWidgetState: WidgetState;
  table: Table
  tableRows: any[];

  toggleSearchBar(): void;
  onFilter(e?: TableFilterEvent): void;
  pageChange($event: TablePageEvent): void;
  downloadTableData(): void;
}

export declare interface AtomTable extends AtomTableBase {
  tableInfo: TableInfo;
}

export declare interface AtomFrozenTable extends AtomTableBase {
  tableInfo: FrozenTableInfo;
  frozenTableRows: any[];
}

export const BaseNoResultViewContext: CustomTableViewContext = {
  padding: SupportedPadding.Padding90,
  templateType: TemplateType.Info,
  customHeader: 'No results match your search',
  customContent: ['Please try another search entry']
}

export interface CustomTableViewContext {
  padding?: SupportedPadding;
  templateType: TemplateType;
  customHeader: string;
  customContent: string[];
  button?: CustomTableViewButton;
}

export interface CustomTableViewButton {
  label: string;
  icon: string;
  action: () => void;
}

export interface TableInfo {
  noResult: boolean;
  pageSize: number;
  firstDisplayedIndex: number;
  lastDisplayedIndex: number;
  displayTotalCount: number;
  searchValue: string | undefined;
  searchBarExpanded: boolean;
}

export function InitializeTableInfo(ps: number, trl: number): TableInfo {
  return {
    noResult: trl == 0,
    pageSize: ps,
    displayTotalCount: trl,
    firstDisplayedIndex: trl == 0 ? 0 : 1,
    lastDisplayedIndex: trl == 0 ? 0 : trl > ps ? ps : trl,
    searchBarExpanded: false,
    searchValue: undefined
  };
}

export function TableInfoFilterEvent(ft: TableInfo,  tr: number): TableInfo {
  const nr = tr == 0;
  const dtc = nr ? 0 : tr;
  const fdi = nr ? 0 : 1;
  const ldi = nr ? 0 : dtc > ft.pageSize ? ft.pageSize : dtc;

  return {
    noResult: nr,
    pageSize: ft.pageSize,
    displayTotalCount: dtc,
    firstDisplayedIndex: fdi,
    lastDisplayedIndex: ldi,
    searchBarExpanded: ft.searchBarExpanded,
    searchValue: ft.searchValue
  }
}

export function TablePageChange($event: TablePageEvent, ft: TableInfo, tr: number): TableInfo {
  const nr = tr  == 0;
  const dtc = nr ? 0 : tr;
  const fdi = nr ? 0 : 1 + $event.first;
  const ldi = nr ? 0 : (fdi + ft.pageSize) > dtc ? dtc : $event.first + ft.pageSize;

  return {
    noResult: nr,
    pageSize: ft.pageSize,
    displayTotalCount: dtc,
    firstDisplayedIndex: fdi,
    lastDisplayedIndex: ldi,
    searchBarExpanded: ft.searchBarExpanded,
    searchValue: ft.searchValue
  }
}

export interface FrozenTableInfo {
  noResult: boolean;
  pageSize: number;
  unfrozenPageSize: number;
  firstDisplayedIndex: number;
  lastDisplayedIndex: number;
  displayTotalCount: number;
  searchValue: string | undefined;
  searchBarExpanded: boolean;
}

export function InitializeFrozenTableInfo(ps: number, trl: number, ftrl: number): FrozenTableInfo {
  const ups = ps - ftrl;
  const dtc = (trl + ftrl);
  const nr = dtc === 0;
  const fdi = nr ? 0 : 1;
  const ldi = nr ? 0 : trl > ups ? ps : dtc

  return {
    noResult: nr,
    pageSize: ps,
    unfrozenPageSize: ups,
    displayTotalCount: dtc,
    firstDisplayedIndex: fdi,
    lastDisplayedIndex: ldi,
    searchBarExpanded: false,
    searchValue: undefined
  };
}

export function FrozenTableFilterEvent(ft: FrozenTableInfo, ftrl: number, tr: number): FrozenTableInfo {
  const nr = tr == 0;
  const dtc = nr ? 0 : (tr + ftrl);
  const fdi = nr ? 0 : 1;
  const ldi = nr ? 0 : dtc > ft.unfrozenPageSize ? (fdi + ft.unfrozenPageSize) : dtc;

  return {
    noResult: nr,
    pageSize: ft.pageSize,
    unfrozenPageSize: ft.unfrozenPageSize,
    displayTotalCount: dtc,
    firstDisplayedIndex: fdi,
    lastDisplayedIndex: ldi,
    searchBarExpanded: ft.searchBarExpanded,
    searchValue: ft.searchValue
  }
}

export function FrozenTablePageChange($event: TablePageEvent, ft: FrozenTableInfo, tr: number): FrozenTableInfo {
  const nr = tr  == 0;
  const dtc = nr ? 0 : tr;
  const fdi = nr ? 0 : 1 + $event.first;
  const ldi = nr ? 0 : (dtc - $event.first) > ft.unfrozenPageSize ? fdi + ft.unfrozenPageSize : dtc ;

  return {
    noResult: nr,
    pageSize: ft.pageSize,
    unfrozenPageSize: ft.unfrozenPageSize,
    displayTotalCount: dtc,
    firstDisplayedIndex: fdi,
    lastDisplayedIndex: ldi,
    searchBarExpanded: ft.searchBarExpanded,
    searchValue: ft.searchValue
  }
}
