import {Injectable, signal, WritableSignal} from '@angular/core';
import {Router} from "@angular/router";

export interface HeaderInfo {
  icon: string;
  title: string;
}

@Injectable()
export class NavigationService {

  viewSignal: WritableSignal<number>= signal(0);

  orgId: WritableSignal<string> = signal('');
  orgName!: string;
  siteId: WritableSignal<string> = signal('');
  siteName!: string;

  headerInfo: WritableSignal<HeaderInfo> = signal({icon: '', title: ''});

  config: WritableSignal<boolean> = signal(false);

  private dropdownExpanded: boolean = false;

  constructor(private router: Router) {
    this.router.events.pipe(
    ).subscribe((e: any) => {
      if (e.snapshot) {
        if (e.snapshot.data) {
          this.evaluateSnapshotData(e.snapshot.data);
        }
      }
    })
  }

  ///////////////////////////////////////////////////
  //                                               //
  //               Router Data Parsing             //
  //                                               //
  ///////////////////////////////////////////////////

  private evaluateSnapshotData(data: any) {
    if (data.support != undefined) {
      return;
    }


    if(data.headerText && data.headerIcon){
      this.updateHeader({icon: data.headerIcon, title: data.headerText}, data.view);
    }

    if(data.config != undefined){
      if (data.config) {
        this.dropdownExpanded = true;
      }
      this.config.set(data.config);
    }
  }

  private updateHeader(info: HeaderInfo, view?: number){
    this.headerInfo.set(info);

    if (view) {
      this.viewSignal.set(view);
    }
  }

  toggleDropdown(): boolean {
    this.dropdownExpanded = !this.dropdownExpanded;

    return this.dropdownExpanded;
  }

  isDropdownExpanded() {
    return this.dropdownExpanded;
  }
}
