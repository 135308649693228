<atom-form-input [required]="required"
                 [group]="group"
                 [controlName]="controlName"
                 [fieldName]="fieldName">
  <form *ngIf="editable" [formGroup]="group" class="flex w-full flex-column">
    <p-calendar class="p-calendar-styling"
                [minDate]="_minDate"
                [selectionMode]="_selectionMode"
                [style]="{'width': '100%', height: '100%'}"
                [inputStyle]="{'width': '188px'}"
                [formControlName]="controlName"
                [timeOnly]="_timeOnly"
                [showTime]="_showTime"
                [hourFormat]="_hourFormat"
                [stepMinute]="_stepMinute"
                [defaultDate]="_defaultDate"
                [showButtonBar]="_buttonBar"
                [todayButtonStyleClass]="_todayButtonStyle"
                [clearButtonStyleClass]="_clearButtonStyle"
                [readonlyInput]="_readOnlyInput"
                [placeholder]="_placeholder"
                (onSelect)="onDateSelect($event)"
                (onInput)="onDateInput($event)"
                panelStyleClass="line-chart-datepicker-position"/>
    <span *ngIf="_description" class="text-xs secondary-text-color">{{_description}}</span>
  </form>
  <ng-container *ngIf="!editable">
    <div *ngIf="group.controls[controlName].value" class="configuration-input-label-field w-full">
      {{ calendarSelectedValue }}
    </div>
    <div *ngIf="!group.controls[controlName].value" class="configuration-input-label-field w-full">
      {{ nonEditLabelOverride }}
    </div>
  </ng-container>
</atom-form-input>
