import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'atom-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss']
})
export class ButtonBarComponent implements OnInit {

  protected modLeft:boolean= false;

  @Input() declineButton: string = 'Discard Changes';
  @Input() acceptButton: string = 'Save Changes';

  @Output() declineEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() acceptEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.modLeft = document.documentElement.scrollHeight > document.documentElement.clientHeight;
  }

  declineClicked() {
    this.declineEvent.emit();
  }

  acceptClicked() {
    this.acceptEvent.emit();
  }
}
