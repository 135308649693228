import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SidebarModule} from "primeng/sidebar";
import {DockModule} from "primeng/dock";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RippleModule} from "primeng/ripple";
import {NgOptimizedImage} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {CoreSharedModule} from "@core-shared/core-shared.module";
import {HighchartsChartModule} from "highcharts-angular";
import {provideUserIdleConfig} from 'angular-user-idle';
import {environment} from '@environments/environment';
import {MsalInterceptor, MsalModule, MsalRedirectComponent} from "@azure/msal-angular";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
  declarations: [
    AppComponent,
    VerifyEmailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SidebarModule,
    DockModule,
    RippleModule,
    NgOptimizedImage,
    BadgeModule,
    AppRoutingModule,
    CoreSharedModule,
    HighchartsChartModule,
    CoreSharedModule,
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: environment.azureB2C.auth,
        cache: environment.azureB2C.cache,
        system: environment.azureB2C.system
      }),
      {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: '/'
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: environment.azureB2C.interceptorConfig.protectedResourceMap
      }
    ),
  ],
  providers: [
    provideUserIdleConfig({idle: environment.idleTime, timeout: environment.countdownTime}),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
