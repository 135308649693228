import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AtomFormInput, TextFormType} from "@core/interfaces/forms/form";

@Component({
  selector: 'atom-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements AtomFormInput {

  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                             Base form input requirements                             //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  @Input() required: boolean = false;
  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  @Input() editable: boolean = true;

  @Input() contentStyle!: string;
}
