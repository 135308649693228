import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AtomFormInput, TextFormType} from "@core/interfaces/forms/form";
import { AtomFormTextInputOpts } from '@core/interfaces/forms/form-input-text';

@Component({
  selector: 'atom-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: ['./form-text-input.component.scss']
})
export class FormTextInputComponent implements OnInit, AtomFormInput {

  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                             Base form input requirements                             //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  @Input() required: boolean = false;
  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  @Input() editable: boolean = true;


  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                               Text Input specific inputs                             //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  /**
   * type
   *
   * The type of input to be displayed (text, numberCurrency, numberWhole, numberDecimal)
   */
  @Input({required: true}) textFormType!: TextFormType;



  /**
   * AtomFormTextInputOpts
   *
   * Options for the AtomFormTextInput component
   * */
  @Input() options!: AtomFormTextInputOpts;

  /**
   * inputChanged
   *
   * Event emitter for when the input value changes
   */
  @Output() inputChanged: EventEmitter<any> = new EventEmitter<any>();

  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                                   Protected Land                                     //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  /**
   * inputType
   *
   * Tells the input field what type of input to display
   */
  protected inputType!: string;
  /**
   * inputMode
   *
   * Tells the input field what mode to display (currency, decimal, etc.)
   * */
  protected inputMode!: string;
  /**
   * useGrouping
   *
   * If true, the input will use grouping for numbers (e.g. 1,000,000 instead of 1000000)
   * */
  protected useGrouping: boolean = false;
  /**
   * min
   *
   * The minimum value allowed for the input field
   * */
  protected _min!: number;
  /**
   * max
   *
   * The maximum value allowed for the input field
   * */
  protected _max!: number;

  protected _minFractionDigits!: number;

  protected _maxFractionDigits!: number

  protected _suffix!: string;

  protected _nonEditLabelOverride?: string;

  ngOnInit(): void {
    if (!this.options) {
      this.options = {
        fieldCounter: false
      };
    }

    switch (this.textFormType) {
      case TextFormType.text:
        this.processOptions();
        this.inputType = 'text';
        break;
      case TextFormType.numberCurrency:
        this.processOptions();
        this.inputType = 'currency-us';
        this.inputMode = 'currency';
        this._minFractionDigits = 2;
        this._maxFractionDigits = 2;
        this.useGrouping = true;
        this._min = 0;
        break;
      case TextFormType.numberWhole:
        this.processOptions();
        this.inputType = 'integerOnly';
        this._minFractionDigits = 0;
        this._maxFractionDigits = 0;
        break;
      case TextFormType.numberDecimal:
        this.processOptions();
        this._minFractionDigits = this.options.minFractionDigits ? this.options.minFractionDigits : 0;
        this._maxFractionDigits = this.options.maxFractionDigits ? this.options.maxFractionDigits : 20;
        this.inputMode = "decimal";
        break;
    }
  }

  private processOptions() {
    if (this.options) {
      if (this.options.max) {
        this._max = this.options.max;
      }
      if (this.options.min) {
        this._min = this.options.min;
      }
      if (this.options.suffix) {
        this._suffix = this.options.suffix;
      }
      if (this.options.nonEditLabelOverride) {
        this._nonEditLabelOverride = this.options.nonEditLabelOverride;
      }
    }
  }

  protected readonly InputType = TextFormType;
}
