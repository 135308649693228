import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from "@angular/forms";


////////////////////////////////////////////////////////////////
//        ____   ______ ___     ____     __  ___ ______ __    //
//       / __ \ / ____//   |   / __ \   /  |/  // ____// /    //
//      / /_/ // __/  / /| |  / / / /  / /|_/ // __/  / /     //
//     / _, _// /___ / ___ | / /_/ /  / /  / // /___ /_/      //
//    /_/ |_|/_____//_/  |_|/_____/  /_/  /_//_____/(_)       //
//------------------------------------------------------------//
//                                                            //
//  If you add a custom validator to this file, you need to   //
//  return a unique field name for your error.  (I.e. numeric //
//  has a return of { numeric: errMsg })  I suggest making    //
//  the function parameter optional and filling it out your-  //
//  self if you don't think it's going to change often.       //
//                                                            //
//  Once  you have added your custom validator here, you must //
//  go into the component.ts located at:                      //
//                                                            //
//    /core-shared/components/config-builders/input-error/    //
//                                                            //
//  and append an if statement into populateErrors() with     //
//  the custom field you set here and push that message to    //
//  error message array.                                      //
//                                                            //
////////////////////////////////////////////////////////////////

export class AtomValidators {

  static validPassword(errorMsg?: string): ValidatorFn {
    const regexLowercase: RegExp = /^(?=.*[a-z])/;
    const regexUppercase: RegExp = /^(?=.*[A-Z])/;
    const regexNumber: RegExp = /^(?=.*\d)/;
    const regexSpecial: RegExp = /^(?=.*[@$!%*?&])/;

    return (field) => {

      const regexes: RegExp[] = [regexLowercase, regexUppercase, regexNumber, regexSpecial];
      let matchCount: number = regexes.reduce((count, regex) => count + Number(regex.test(field.value)), 0);

      if (!(matchCount >= 3)) {
        return {validPassword: errorMsg ? errorMsg : 'Password must have uppercase, lowercase character, number, and a special character'};
      }

      return null
    }
  }

  static nonEmptyArray(arrayTypeName: string): ValidatorFn {
    return (field) => {
      if (field.value.length === 0) {
        return {nonEmptyArray: 'Selection of at least one ' + arrayTypeName + ' is required'};
      }
      return null;
    };
  }

  static wholeNumber(errorMsg?: string): ValidatorFn {
    const pattern = /^[0-9]*$/;
    return (field) => {
      if (!pattern.test(field.value)) {
        return {wholeNumber: errorMsg ? errorMsg : 'Use only whole numbers'};
      }
      return null;
    };
  }

  static realNumber(errorMsg: string): ValidatorFn {
    let pattern = /^-?[0-9]\d*(\.\d+)?$/;
    return (field) => {
      if (!pattern.test(field.value)) {
        return {custom: errorMsg};
      }
      return null;
    };
  };

  static alphabetical(errorMsg?: string): ValidatorFn {
    const pattern = /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$/;
    return (field) => {
      if (field.value == '') {
        return null;
      }

      if (!pattern.test(field.value)) {
        return {alphabetical: errorMsg ? errorMsg : 'Use only letters A-Z'};
      }
      return null;
    };
  };

  static numeric(errorMsg?: string): ValidatorFn {
    const pattern = /^[0-9]*$/;
    return (field) => {
      if (!pattern.test(field.value)) {
        return {numeric: errorMsg ? errorMsg : 'Use only digits 0-9'};
      }
      return null;
    };
  };

  static alphaNumeric(space: boolean, errorMsg?: string): ValidatorFn {
    let pattern: RegExp;

    if (space) {
      pattern = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/;
    } else {
      pattern = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$/;
    }

    return (field) => {
      if (field.value == '') {
        return null;
      }

      if (!pattern.test(field.value)) {
        if (!errorMsg) {
          if (space) {
            return {alphaNumeric: 'Use only letters A-Z, spaces, and digits 0-9'};
          } else {
            return {alphaNumeric: 'Use only letters A-Z and digits 0-9'};
          }
        } else {
          return {alphaNumeric: errorMsg};
        }
      }
      return null;
    };
  }

  static ipv4WithPort(errorMsg?: string): ValidatorFn {
    const pattern = /^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}:(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9]{3}|[1-5][0-9]{4}|[1-9][0-9]{0,3}|[0-9])$/;
    return (field) => {
      if (field.value == '') {
        return null;
      }

      if (!pattern.test(field.value)) {
        return {ipv4WithPort: errorMsg ? errorMsg : 'IP must be in x.x.x.x:xxxx format'};
      }
      return null;
    };
  };

  static stationIndex(errorMsg: string): ValidatorFn {
    const pattern = /^[0-1]*$/;
    return (field) => {
      if (!pattern.test(field.value)) {
        return {stationIndex: errorMsg};
      }
      return null;
    };
  };

  static optionalPhone(errorMsg?: string): ValidatorFn {
    const pattern = /^[0-9]*$/;
    return (field) => {
      if (field == null || field.value == null || field.value == '') {
        return null;
      }
      if (field.value.length < 7
        || field.value.length > 10
        || !pattern.test(field.value)) {
        return {optionalPhone: errorMsg ? errorMsg : 'Invalid phone number'};
      }
      return null;
    };
  };

  static phoneExt(errorMsg?: string): ValidatorFn {
    return (field) => {
      if (field == null || field.value == null || field.value == '') {
        return null;
      }
      if (field.value.length > 6) {
        return {phoneExt: errorMsg ? errorMsg : 'Invalid phone extension'};
      }
      return null;
    };
  };

  static golangEmail(errorMsg?: string): ValidatorFn {
    const pattern = /^(?:(?:(?:(?:[a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(?:\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|(?:(?:\x22)(?:(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(?:\x20|\x09)+)?(?:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])))*(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(\x20|\x09)+)?(?:\x22))))@(?:(?:(?:[a-zA-Z]|\d)|(?:(?:[a-zA-Z]|\d)(?:[a-zA-Z]|\d|-|\.|~)*(?:[a-zA-Z]|\d)))\.)+(?:(?:[a-zA-Z])|(?:(?:[a-zA-Z])(?:[a-zA-Z]|\d|-|\.|~)*(?:[a-zA-Z])))\.?$/
    return (field) => {
      if (!pattern.test(field.value)) {
        return {apiEmail: errorMsg ? errorMsg : "Invalid email format"};
      }
      return null;
    };
  };

  static matchPasswordValidator(password: FormControl): ValidatorFn {
    return (field) => {
      return field.value !== password.value ? {passwordMatch: "Passwords do not match"} : null;
    }
  };

  static stateSelected(): ValidatorFn {
    return (field) => {
      return field.value.code === '' || field.value.code == undefined ? {stateSelected: "State is required"} : null;
    };
  };

  static endTimeValidator(startDate: FormControl): ValidatorFn {
    return (field) => {
      if (startDate.value != null && field.value != null) {
        // Remove millisecond precision
        const endTime = ((field.value as Date).getTime() / 1000) | 0;
        const startTime = ((startDate.value as Date).getTime() / 1000) | 0;

        return endTime <= startTime ? {endTime: "End time must be later than start time"} : null;
      }
      return null;
    }
  }

  static endTimeDateValidator(startTime: FormControl): ValidatorFn {
    return (field) => {
      if (startTime.value != null && field.value != null) {
        let st: Date = startTime.value
        st.setDate(1);
        st.setMonth(1);
        st.setFullYear(2000);

        let et: Date = field.value;
        et.setDate(1);
        et.setMonth(1);
        et.setFullYear(2000);

        return et <= st ? {endTime: "End time must be later than start time"} : null;
      }
      return null;
    }
  }

  static startTimeValidator(endDate: FormControl): ValidatorFn {
    return (field) => {
      if (endDate.value != null && field.value != null) {
        // Remove millisecond precision
        const startTime: number = ((field.value as Date).getTime() / 1000) | 0;
        const endTime: number = ((endDate.value as Date).getTime() / 1000) | 0;

        return startTime >= endTime ? {startTime: "Start time must be earlier than end time"} : null;
      }
      return null;
    }
  }

  static endDateValidator(startDate: FormControl): ValidatorFn {
    return (field) => {
      if (startDate.value != null && field.value != null) {
        return field.value <= startDate.value ? {endTime: "End Date must be after Start Date"} : null;
      }
      return null;
    }
  }
}


export const requireRoleValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  // roleIds is an array of numbers
  const roleIds = control.get('roleId');
  const add = control.get('add');

  return add?.value === true && (roleIds?.value == null || roleIds?.value?.length == 0) ? {roleRequired: true} : null;
};


