import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {entrypointResolver} from "@global/resolvers/entrypoint.resolver";
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const routes: Routes = [
  {
    path: 'verify-email/:userType/:id/:code/:organizationId',
    component: VerifyEmailComponent
  },
  {
    path: '',
    redirectTo: 'core',
    pathMatch: 'full'
  },
  {
    path: 'core',
    canActivate: [MsalGuard],
    resolve: {user: entrypointResolver},
    loadChildren: () => import('./core/core.module').then(c => c.CoreModule),
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true, paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
