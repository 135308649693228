<div class="flex justify-content-center align-items-start flex-1 gap-5 border-round-lg custom-styling">
    <div *ngIf="widgetState == WidgetState.Good || widgetState == WidgetState.Info" class="flex flex-column align-items-center flex-1 gap-3">
      <span class="text-base font-normal text-center text-overflow-ellipsis overflow-hidden custom-text-line-height" *ngIf="header">{{header}}</span>
      <ng-container *ngIf="contentTemplate">
        <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
      </ng-container>
      <ng-container *ngIf="!contentTemplate">
        <span class="font-normal font-semibold text-center custom-text-style custom-text-line-height" *ngIf="content">{{content}}</span>
      </ng-container>
    </div>
    <div *ngIf="widgetState == WidgetState.Error" class="flex flex-column align-items-center flex-1 gap-3">
        <atom-widget-error [widgetTitle]="errorHeaderText ? errorHeaderText :header"></atom-widget-error>
    </div>
    <div *ngIf="widgetState == WidgetState.Loading" class="flex body-content-container">
        <p-progressSpinner [style]="{ width: '100px', height: '100px', color: 'purple'}">
        </p-progressSpinner>
      </div>
</div>
