import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {
  Organization, OrganizationContactResponse,
  OrganizationList, UpsertOrgContactRequest, UpsertOrganizationRequest
} from '@core-shared/models/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  baseUrl = `${environment.apiEndpoint}/organizations`

  constructor(private http: HttpClient) {
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                           _____ ______ ____   ____   ___    ______ ______                            //
  //                          / ___//_  __// __ \ / __ \ /   |  / ____// ____/                            //
  //                          \__ \  / /  / / / // /_/ // /| | / / __ / __/                               //
  //                         ___/ / / /  / /_/ // _, _// ___ |/ /_/ // /___                               //
  //                        /____/ /_/   \____//_/ |_|/_/  |_|\____//_____/                               //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  get organizationName(): string | null {
    return sessionStorage.getItem('organizationName');
  }

  setOrganizationName(name: string) {
    sessionStorage.setItem('organizationName', name);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                     ______ ____   __  __ ____                                        //
  //                                    / ____// __ \ / / / // __ \                                       //
  //                                   / /    / /_/ // / / // / / /                                       //
  //                                  / /___ / _, _// /_/ // /_/ /                                        //
  //                                  \____//_/ |_| \____//_____/                                         //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////
  //  Organization

  getOrganizations(): Observable<OrganizationList> {
    return this.http.get<OrganizationList>(this.baseUrl);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}/${organizationId}`);
  }

  createOrganization(data: UpsertOrganizationRequest): Observable<Organization> {
    return this.http.post<Organization>(`${this.baseUrl}`, data);
  }

  updateOrganization(organizationId: string, organization: UpsertOrganizationRequest): Observable<Organization> {
    return this.http.put<Organization>(`${this.baseUrl}/${organizationId}`, organization);
  }

  /////////////////////////////////////////
  //  Organization Contact

  getOrganizationContact(organizationId: string): Observable<OrganizationContactResponse> {
    return this.http.get<OrganizationContactResponse>(`${this.baseUrl}/${organizationId}/org-contact`)
  }

  upsertOrganizationContact(organizationId: string, uoc: UpsertOrgContactRequest): Observable<OrganizationContactResponse> {
    return this.http.put<OrganizationContactResponse>(`${this.baseUrl}/${organizationId}/org-contact`, uoc)
  }
}
