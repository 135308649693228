import {Component, Input} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'atom-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent {

  @Input() visible: boolean = false;

  declineHeaderText: string = 'Discard Unsaved Changes?';
  declineBodyText: string = 'You have unsaved changes on this page. If you discard you will lose your changes.';

  modalDiscardText: string = 'Discard Changes';
  modalContinueText: string = 'Continue Editing';

  constructor(public ref: DynamicDialogRef) {
  }


  onDiscard(close: boolean) {
    this.ref.close(close)
  }
}
