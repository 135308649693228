import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'atom-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {
  @Input() closable: boolean = false;

  @Input() headerText: string = 'Submission Error';
  @Input() contentText: string = 'Please fix the errors highlighted below';

  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();

  closeClicked() {
    this.closeClick.emit();
  }
}
