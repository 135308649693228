import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {InputSwitch} from "primeng/inputswitch";
import {AtomRouteInfo} from "@models/navigation.model";
import {Params} from "@angular/router";

export interface headerDropDownItem {
  name: string,
  model: any
}

@Component({
  selector: 'atom-config-header',
  templateUrl: './config-header.component.html',
  styleUrls: ['./config-header.component.scss']
})
export class ConfigHeaderComponent implements OnInit, OnChanges {

  @Input() headerLinkLabel!: string;
  @Input() headerRouteInfo!: AtomRouteInfo;

  protected _routeParams?: Params | null;

  @Input() headerTitle!: string;
  @Input() headerSubtitle!: string;

  @Input() toggleVisible: boolean = false;
  @Input() toggleLabel!: string;
  @Input() toggleState!: boolean;

  @Output() toggleEvent = new EventEmitter<boolean>();

  @Input() rightHeaderCustom: boolean = false;
  @Input() rightHeaderTemplate: TemplateRef<any> | undefined;

  @ViewChild('toggle') toggle!: InputSwitch;

  // Prevents the toggle option from changing but emits a signal to the parent to handle
  toggleFlip(sw: InputSwitch) {
    sw.writeValue(!sw.modelValue)
    this.toggleEvent.emit(this.toggleState);
  }

  ngOnInit() {
    if (this.headerRouteInfo) {
      if (this.headerRouteInfo.options) {
        this._routeParams = this.headerRouteInfo.options.queryParams;
      }
    }
  }

  // Allows the parent to control if the input switch is actually toggled
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggleState && this.toggle) {
      this.toggle.writeValue(changes.toggleState.currentValue);
    }
  }
}
