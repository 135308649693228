<atom-form-input
  [required]="required"
  [group]="group"
  [controlName]="controlName"
  [fieldName]="fieldName"
  [fieldCounter]="options.fieldCounter ? options.fieldCounter : false"
  [maxLen]="options.maxLen ? options.maxLen : 0">

  <form [formGroup]="group" class="flex w-full flex-column">
    <input *ngIf="textFormType === InputType.text && editable"
           [id]="controlName"
           class="configuration-input-field w-full"
           [placeholder]="options.placeholder ? options.placeholder : ''"
           [formControlName]="controlName"
           [type]="inputType"
           (input)="inputChanged.emit($event)"
           pInputText/>
    <p-inputNumber *ngIf="textFormType != InputType.text && editable"
                   [id]="controlName"
                   class="input-number-config-input-field"
                   [min]="_min"
                   [max]="_max"
                   [formControlName]="controlName"
                   [suffix]="options.suffix"
                   [inputId]="inputType"
                   [minFractionDigits]="_minFractionDigits"
                   [maxFractionDigits]="_maxFractionDigits"
                   (onInput)="inputChanged.emit($event)"
                   [mode]="inputMode"
                   locale="en-US"/>
    <span *ngIf="options.description" class="text-xs secondary-text-color">{{options.description}}</span>
    <span *ngIf="!editable" class="configuration-input-label-field w-full">
      {{ _nonEditLabelOverride ? _nonEditLabelOverride : group.controls[controlName].value }}{{ _suffix && !_nonEditLabelOverride ? _suffix : ''}}
    </span>
  </form>
</atom-form-input>
