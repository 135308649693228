<div *ngIf="editable" class="flex flex-column gap-3 field-checkbox align-items-start">
  <ng-container *ngIf="group && controlName">
    <form [formGroup]="group" *ngFor="let radio of options" class="flex flex-row">
      <p-radioButton
        [value]="radio.value"
        [formControlName]="controlName"
        (onClick)="onChange($event)"
        [inputId]="radio.value"/>
      <div class="flex flex-column ml-3">
        <label [for]="radio.value" class="text-sm"> {{ radio.label }}</label>
        <label *ngIf="radio.description" class="text-xs secondary-text-color">{{ radio.description }}</label>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="!group && !controlName">
    <div *ngFor="let radio of options" class="flex flex-row">
      <p-radioButton
        [value]="radio.value"
        [name]="radio.value"
        [(ngModel)]="model"
        (onClick)="onChange($event)"
        [inputId]="radio.value"/>
      <div class="flex flex-column ml-3">
        <label [for]="radio.value" class="text-sm"> {{ radio.label }}</label>
        <label *ngIf="radio.description" class="text-xs secondary-text-color">{{ radio.description }}</label>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="!editable" class="flex flex-column gap-3 align-items-start">
  <label class="text-sm"> {{ label }}</label>
  <label *ngIf="description" class="text-xs secondary-text-color">{{ description }}</label>
</div>
