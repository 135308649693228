import {Component, Input, TemplateRef} from '@angular/core';
import { WidgetState } from '../widget-container/widget-state';

@Component({
  selector: 'atom-centered-widget-container',
  templateUrl: './centered-widget-container.component.html',
  styleUrls: ['./centered-widget-container.component.scss']
})
export class CenteredWidgetContainerComponent {
@Input() header!: string;
@Input() content!: string;
@Input() contentTemplate!: TemplateRef<any>;
@Input() widgetState: WidgetState = WidgetState.Loading;
@Input() errorHeaderText!: string;

protected readonly WidgetState = WidgetState;

}
