import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {AtomFormInput} from "@core/interfaces/forms/form";
import {RadioOption} from "@models/radio.model";
import { RadioButtonClickEvent } from 'primeng/radiobutton';

@Component({
  selector: 'atom-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent implements OnInit, AtomFormInput {

  @Input() required: boolean = false;
  @Input() group!: FormGroup<any>;
  @Input() controlName!: string;
  @Input() fieldName!: string;
  @Input() editable: boolean = true;

  @Input() model!: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input({required: true}) options!: RadioOption[];

  protected label: string = '';
  protected description?: string ;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.editable) {
      if (this.group && this.controlName) {
        this.label = this.options.find(option => option.value === this.group.get(this.controlName)?.value)?.label || '';
        this.description = this.options.find(option => option.value === this.group.get(this.controlName)?.value)?.description || undefined;
      } else if (!this.group && !this.controlName && this.model) {
        this.label = this.options.find(option => option.value === this.model)?.label || '';
        this.description = this.options.find(option => option.value === this.model)?.description || undefined;
      }
    }
  }

  onChange(event: RadioButtonClickEvent) {
    this.modelChange.emit(event.value);
  }

  protected readonly FormGroup = FormGroup;
}
