<div class="confirm-content-container">
    <ng-container *ngFor="let msg of contentMessages">
      <span *ngIf="msg.bold" class="content-text-bold">
        {{msg.contentMsg}}
      </span>
      <span *ngIf="!msg.bold" class="content-text">
        {{msg.contentMsg}}
      </span>
    </ng-container>
</div>
<div [ngClass]="{
                  'confirm-footer-container-double': doubleButton,
                  'confirm-footer-container-single': !doubleButton
                }">
  <p-button *ngIf="outlineText !== undefined" class="outline-button" [label]="outlineText" (onClick)="onChoice(false)"></p-button>
  <p-button *ngIf="solidText !== undefined" [label]="solidText" (onClick)="onChoice(true)"></p-button>
</div>
