import { Injectable } from '@angular/core';
import DialogMessages from "@constants/dialog.json";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DialogRefConfig} from "@models/dialog";
import {Observable} from "rxjs";
import {ConfirmDialogComponent} from "@core-shared/components/confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  protected readonly dialogMessages = DialogMessages;

  constructor(private dialogService: DialogService) { }

  private getOnCloseObservable(config: DialogRefConfig): Observable<any> {
    const ref: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, {
      header: config.header,
      data: config.data,
      closable: config.closeable != undefined ? config.closeable : true,
      closeOnEscape: config.closeable != undefined ? config.closeable : true,
    });

    return  ref.onClose;
  }

  BuildAndOpenConfirmationModal(jsonRef: DialogRefConfig, args?: string[]): Observable<any> {

    if (args) {
      jsonRef.header = this.findAndReplaceWildcards(jsonRef.header, args);

      jsonRef.data.dialogContent.forEach((content) => {
        content.contentMsg = this.findAndReplaceWildcards(content.contentMsg, args);
      })
    }

    return this.getOnCloseObservable(jsonRef);
  }

  private findAndReplaceWildcards(msg: string, args: string[]): string {
    let newMsg: string = "";
    const regex: RegExp = /\$\d+/g;

    // Find all the $x in the string
    const matches: RegExpMatchArray[] = [...msg.matchAll(regex)];

    // Check if we have a $x in the string
    if (matches.length === 0) {
      return msg;
    }

    // Create a set to store the unique indexes
    let indexes: Set<number> = new Set();

    // Loop through the matches and add the index to the set
    matches.forEach((match) => {
      if (match.length == 0) {
        return;
      }

      const index: number = parseInt(match[0].substring(1));

      indexes.add(index);
    })

    // Find the max index
    const maxIndex: number = Math.max(...Array.from(indexes));

    // Check if the max index is greater than the number of args
    if (maxIndex > args.length) {
      return msg;
    }

    // Loop through the indexes and replace the $x with the corresponding arg
    indexes.forEach((index) => {
      newMsg = msg.replace("$" + index, args[index - 1]);
    })

    return newMsg;
  }
}
