
export enum AtomCalendarStyle {
  Date,
  Time,
  DateTime
}

export interface AtomFormCalendarOpts {

  /**
   * description
   *
   * Some descriptive text placed under the date selector
   */
  description?: string;

  /**
   * placeholder
   *
   * The placeholder text for the calendar when no date is selected.
   */
  placeholder?: string;

  /**
   * buttonBar
   *
   * Whether or not to display the button bar at the bottom of the calendar.
   *
   * @Default false
   */
  buttonBar?: boolean;

  /**
   * hideClearButton
   *
   * Whether or not to display the clear button on the calendar buttonBar.  {@link buttonBar} must be true for this to take effect.
   *
   * @Default false
   */
  hideClearButton?: boolean;

  /**
   * hideTodayButton
   *
   *
   * Whether or not to display the now button on the calendar buttonBar.  {@link buttonBar} must be true for this to take effect.
   *
   * @Default false
   */
  hideTodayButton?: boolean;

  /**
   * defaultDate
   *
   * Initial date selected when opening the overlay.
   */
  defaultDate?: Date;

  /**
   * minDate
   *
   * Earliest date that can be selected.
   */
  minDate?: Date;

  /**
   * dateRange
   *
   * Whether to allow a range of dates to be selected.
   *
   * @Default false
   */
  dateRange?: boolean;

  /**
   * hourFormat
   *
   * The format of the hour in the time picker. 12 or 24.
   *
   * @Default 12
   */
  hourFormat?: string;

  /**
   * stepMinute
   *
   * The step interval for minutes in the time picker.
   *
   * @Default 15
   */
  stepMinute?: number;

  /**
   * nonEditLabelOverride
   *
   * Label to display in place of date/time when the time is not editable.
   */
  nonEditLabelOverride?: string;

  /**
   * readOnlyInput
   *
   * Whether or not the input field is read only.
   *
   * @Default true
   */
  readOnlyInput?: boolean;
}
