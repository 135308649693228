import {Component, OnInit} from '@angular/core';
import {WidgetState} from "../widget-container/widget-state";

@Component({
  selector: 'atom-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss']
})
export class NotificationWidgetComponent implements OnInit {

  notificationState: WidgetState = WidgetState.Good;
  notificationHeaderText: string = 'System Notifications';
  notificationBodyCount!: number;
  notificationFooterText: string = 'See All Notifications';
  bodyText = "No new System Notifications";

  ngOnInit(): void {
    this.notificationState = WidgetState.Good;
    this.notificationBodyCount = 0;
  }


  protected readonly WidgetState = WidgetState;
}
