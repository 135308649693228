import {ModuleWithProviders, NgModule} from '@angular/core';
import {AccordionModule} from 'primeng/accordion';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmationService, SharedModule} from 'primeng/api';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog'
import {DialogService} from 'primeng/dynamicdialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenuModule} from 'primeng/menu';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RippleModule} from 'primeng/ripple';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from "primeng/sidebar";
import {StepsModule} from 'primeng/steps';
import {StyleClassModule} from 'primeng/styleclass';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast'
import {TooltipModule} from 'primeng/tooltip';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {TabViewModule} from 'primeng/tabview';
import {PasswordModule} from 'primeng/password';
import {AutoFocusModule} from "primeng/autofocus";
import {ToolbarModule} from "primeng/toolbar";
import {MessageService} from "primeng/api";
import {Menubar, MenubarModule} from "primeng/menubar";

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    ToolbarModule,
    StepsModule,
    AccordionModule,
    AutoFocusModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    MenuModule,
    MultiSelectModule,
    PanelModule,
    ProgressSpinnerModule,
    RippleModule,
    SelectButtonModule,
    SidebarModule,
    StepsModule,
    StyleClassModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
    PasswordModule,
    MenubarModule
  ],
  exports: [
    SharedModule,
    ToolbarModule,
    StepsModule,
    AccordionModule,
    AutoFocusModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    DynamicDialogModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    MenuModule,
    MultiSelectModule,
    PanelModule,
    ProgressSpinnerModule,
    RippleModule,
    SelectButtonModule,
    SidebarModule,
    StepsModule,
    StyleClassModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
    PasswordModule,
    MenubarModule
  ],
  providers: [MessageService]
})
export class PrimengModule {
  constructor() {
  }

  static forRoot(): ModuleWithProviders<PrimengModule> {
    return {
      ngModule: PrimengModule,
      providers: [
        DialogService,
        ConfirmationService,
        MessageService
      ]
    };
  }
}
