<atom-form-input
  [ngClass]="{
    'w-full': dropdownOpts.dropdownStyle != AtomDropdownStyle.CountryCode,
    'form-input-dropdown-sizing': dropdownOpts.dropdownStyle == AtomDropdownStyle.CountryCode,
  }"
  [required]="required"
  [group]="group"
  [controlName]="controlName"
  [fieldName]="fieldName">
  <form [formGroup]="group" *ngIf="editable" class="flex w-full">
    <p-dropdown id="meter-type"
                [ngClass]="{
                  'dropdown-sizing': dropdownOpts.dropdownStyle != AtomDropdownStyle.CountryCode,
                  'phone-dropdown-sizing': dropdownOpts.dropdownStyle == AtomDropdownStyle.CountryCode,
                }"
                [options]="options"
                [formControlName]="controlName"
                (onChange)="onDropdownChange($event)"
                [optionLabel]="label"
                [optionValue]="value"
                [placeholder]="placeholder">
      <ng-template *ngIf="!dropdownOpts.selectedItemTemplate" let-item pTemplate="selectedItem">
        <div class="flex align-items-center" *ngIf="item">
          <div>{{ label ? item[label] : item }}</div>
        </div>
      </ng-template>
      <ng-template *ngIf="dropdownOpts.selectedItemTemplate" let-item pTemplate="selectedItem">
        <ng-container [ngTemplateOutlet]="dropdownOpts.selectedItemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"/>
      </ng-template>
      <ng-template *ngIf="!dropdownOpts.itemTemplate" let-item pTemplate="item">
        <div class="flex align-items-center">
          <div>{{ label ? item[label] : item }}</div>
        </div>
      </ng-template>
      <ng-template *ngIf="dropdownOpts.itemTemplate" let-item  pTemplate="item">
        <ng-container [ngTemplateOutlet]="dropdownOpts.itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"/>
      </ng-template>
    </p-dropdown>
  </form>
  <span *ngIf="!editable" class="configuration-input-label-field w-full">{{ label ? group.controls[controlName].value[label] : group.controls[controlName].value }}</span>
</atom-form-input>
