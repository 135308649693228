import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { PermissionsService } from '@core/services/permissions/permissions.service';
@Directive({
  selector: '[appFeatureControl]'
})
export class FeatureControlDirective {
  private type!: 'disable' | 'remove' | 'block' | 'hidden';
  private ref!: EmbeddedViewRef<any>;
  private permission!: string[];
  private id!: string;
  private userPermissions!: string[];
  @Input()
  set appFeatureControl(value: any) {
    this.permission = value;
    this.process();
  }

  @Input()
  set appFeatureControlType(value: 'disable' | 'remove' | 'block' | 'hidden') {
    this.type = value;
    this.process();
  }

  @Input()
  set appFeatureControlId(value: string) {
    this.id = value;
    this.process();
  }
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private element: ElementRef,
    private permService: PermissionsService,
    private renderer: Renderer2) {
  }

  process() {
    if (!this.type || !this.permission || !this.id) {
      this.show();
      return;
    }
    //allow all access for super user -> so no checking required
    if (this.permService.isAdmin()) {
      return;
    } else { // if not super user then check permission
      if (this.permService.isOrgAdmin()) {
        this.userPermissions = this.permService.getOrgPermissions();
      } else {
        this.userPermissions = this.permService.getSitePermissions()
      }
      if (this.permission.some(item => this.userPermissions?.includes(item))) { // if user has the required permission, show the component
        this.show()
        return;
      } else { //if user does not have permissions then perform the required functions
        switch (this.type) {
          case 'hidden':
            this.hidden();
            break;
          case 'remove':
            this.remove();
            break;
          case 'block':
            this.block();
            break;
        }
      }
    }
  }

  remove() {
    this.viewContainer.clear();
  }

  block() {
    this.renderer.addClass(this.element.nativeElement.previousElementSibling, 'block-ui');
    this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'inert', 'true');
  }

  hidden() {
    this.element.nativeElement.previousElementSibling.hidden = true;
  }

  show() {
    if (this.ref) {
      this.element.nativeElement.previousElementSibling.hidden = false;
      return;
    }
    this.ref = this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
