import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {DialogContent} from "@models/dialog";
import {Observable} from "rxjs";

@Component({
  providers: [DialogService],
  selector: 'atom-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  dialogContent!: DialogContent | undefined;

  outlineText!: string | undefined;
  solidText!: string | undefined;

  closeable: boolean = true;
  doubleButton: boolean = false;

  contentMessages!: DialogContent[];

  constructor(private dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef) {
  }

  ngOnInit(): void {
    if (this.dialogConfig) {
      this.contentMessages = this.dialogConfig.data.dialogContent;
      this.outlineText = this.dialogConfig.data.outlineText;
      this.solidText = this.dialogConfig.data.solidText;
      this.doubleButton = this.outlineText !== undefined && this.solidText !== undefined;
      this.closeable = this.dialogConfig.closable ? this.dialogConfig.closable : true;
    }
  }

  onChoice(choice: boolean) {
    this.ref.close(choice);
  }

  close(): Observable<boolean | null> {
    return this.ref.onClose as Observable<boolean>
  }
}
