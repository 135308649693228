<div class="cancel-container">
  <div class="cancel-header">
    <div class="modal-header-outer">
      <div class="modal-header-inner">
        <span class="header-text">{{declineHeaderText}}</span>
        <div class="icon-container-outer" (click)="onDiscard(false)">
          <div class="icon-container-inner" >
            <svg  class="icon-styling" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask id="mask0_8350_69555" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_8350_69555)">
                <path d="M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.40002L6.40002 5.34619L12 10.9462L17.6 5.34619L18.6538 6.40002L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cancel-content">
    <div class="content-text-container">
      <span class="content-text">
        {{declineBodyText}}
      </span>
    </div>
  </div>
  <div class="cancel-footer">
    <p-button id="discard-changes"
              styleClass="flex align-items-center gap-2 text-sm font-semibold p-button-secondary min-w-max h-full"
              [label]="modalDiscardText"
              (onClick)="onDiscard(true)">
    </p-button>
    <p-button [label]="modalContinueText"
              (onClick)="onDiscard(false)">

    </p-button>
  </div>
</div>
