import {Injectable, effect, WritableSignal, signal} from '@angular/core';
import {
  EntryPoint,
  OrganizationPermission,
  OrganizationResponse,
  PermissionTree,
  SitePermission,
  User
} from "@global/models/user-data.model";
import {NavigationService} from "@core/services/navigation/navigation.service";
import {Router} from "@angular/router";
import {v4 as uuidv4} from 'uuid';
import {OrganizationsService} from "@core-shared/services/organizations/organizations.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  initialized: WritableSignal<boolean> = signal(false);
  updated: WritableSignal<string> = signal('');

  private accountPermissions!: PermissionTree;

  private admin: boolean = false;

  private multiOrg: boolean = false;
  private organizations: OrganizationResponse[] = [];

  private orgPermissions: string[] = [];
  private sitePermissions: string[] = [];

  private user!: User;

  private orgAdmin: boolean = false;
  private orgViewer: boolean = false;
  private siteAdmin: boolean = false;
  private siteViewer: boolean = false;

  private engineer: boolean = false;
  private installer: boolean = false;
  private firmware: boolean = false;

  constructor(private navService: NavigationService,
              private orgService: OrganizationsService,
              private router: Router) {
    effect(() => {
      if (navService.orgId() || navService.siteId()) {
        this.updatePermissions();
      }
    }, {allowSignalWrites: true})

    this.router.events.pipe(
    ).subscribe((e: any) => {
      if (e.params) {
        this.parseUrlParams(e.params);
      }
    })
  }

  private parseUrlParams(params: any) {
    if (params.orgId) {
      this.navService.orgId.set(params.orgId);
    }

    if (params.siteId) {
      this.navService.siteId.set(params.siteId);
    }
  }

  initPermissions(user: EntryPoint) {
    this.user = user.user;

    if (!this.accountPermissions) {
      this.accountPermissions = {
        organizationPermissions: new Map<string, OrganizationPermission>()
      };
    }

    if (this.user.firmwareUser) {
      this.firmware = true;
    }

    if (this.user.superUser) {
      this.getAllOrganizations();
    } else {
      this.parsePermissions(user)
      this.initialized.set(true);
    }

  }

  getAllOrganizations() {
    this.orgService.getOrganizations().subscribe((orgs) => {
      orgs.organizations.forEach((org) => {
        this.organizations.push({
          id: org.organizationId,
          name: org.name
        });

        // Set the multiOrg flag
        this.multiOrg = this.organizations.length > 1;
        this.initialized.set(true);
      })
    })
  }


  parsePermissions(user: EntryPoint) {
    //  NOTE: We have to stringify and parse the permissions to get around the Map type,
    //  as the initially parsed object from Entrypoint is not a true Map and does not have the Map methods
    let permJSON = JSON.stringify(user.permissionTree.organizationPermissions);
    let parsedPerms = JSON.parse(permJSON);

    // Get the org keys
    // const orgKeys = parsedPerms ? Object.keys(parsedPerms) : [];
    const orgKeys = Object.keys(parsedPerms);

    // Iterate over them
    orgKeys.forEach((key) => {
      // Init a new map for the site permissions
      let siteRemap = new Map<string, SitePermission>();

      // Get the org value
      let orgValue = parsedPerms[key];

      // Push the org to the organizations array
      this.organizations.push({
        id: key,
        name: orgValue.name
      })

      this.organizations.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      });

      // Iterate over the site permissions
      Object.keys(orgValue.sitePermissions).forEach((key) => {
        // Get the site value
        let siteValue = orgValue.sitePermissions[key];

        // Push the site permissions to the siteRemap
        siteRemap.set(key, siteValue);
      });

      // Push the org permissions to the accountPermissions
      this.accountPermissions.organizationPermissions.set(key, {
        name: orgValue.name,
        accessLevels: orgValue.accessLevels,
        sitePermissions: siteRemap
      });
    })

    // Set the multiOrg flag
    this.multiOrg = this.accountPermissions.organizationPermissions.size > 1;
  }

  updatePermissions() {
    if (this.navService.orgId() == '') {
      this.navService.orgId.set(this.user.organizationId);
    }

    const orgPerms = this.accountPermissions.organizationPermissions.get(this.navService.orgId());

    if (orgPerms) {
      this.orgPermissions = orgPerms.accessLevels;
    } else {
      this.orgPermissions = [];
    }

    const sitePerms = this.accountPermissions.organizationPermissions.get(this.navService.orgId())?.sitePermissions.get(this.navService.siteId());

    if (sitePerms) {
      this.sitePermissions = sitePerms.accessLevels;
    } else {
      this.sitePermissions = [];
    }

    this.updateRoles();
    this.updated.set(uuidv4())
  }

  resetRoles() {
    this.admin = false
    this.orgAdmin = false;
    this.orgViewer = false;
    this.siteAdmin = false;
    this.siteViewer = false;
    this.engineer = false;
    this.installer = false;
  }

  updateRoles() {
    this.resetRoles();

    if (this.user.superUser) {
      this.admin = true;
      this.orgAdmin = true;
      this.orgViewer = true;
      this.siteAdmin = true;
      this.siteViewer = true;
      this.engineer = true;
      this.installer = true;
      return;
    }

    this.orgPermissions.forEach((perm: string) => {
      switch (perm) {
        case "Engineering":
          this.engineer = true;
          break;
        case "Installer":
          this.installer = true;
          break;
        case "OrgAdmin":
          this.orgAdmin = true;
          this.orgViewer = true;
          this.siteAdmin = true;
          this.siteViewer = true;
          break;
        case "OrgViewer":
          this.orgViewer = true;
          this.siteViewer = true;
          break;
      }
    })

    if (this.sitePermissions) {
      this.sitePermissions.forEach((perm: string) => {
        switch (perm) {
          case "Engineering":
            this.engineer = true;
            break;
          case "Installer":
            this.installer = true;
            break;
          case "SiteAdmin":
            this.siteAdmin = true;
            this.siteViewer = true;
            break;
          case "SiteViewer":
            this.siteViewer = true;
            break;
        }
      })
    }
  }

  getUserName(): string {
    return this.user.displayName;
  }

  getUserOrganization(): string {
    return this.user.organizationId;
  }

  isSuperUser(): boolean {
    return this.user.superUser;
  }

  isFirmware(): boolean {
    return this.firmware
  }

  isAdmin(): boolean {
    return this.admin;
  }

  isMultiOrg(): boolean {
    return this.multiOrg;
  }

  getOrganizations(): OrganizationResponse[] {
    return this.organizations;
  }

  getOrgPermissions(): string[] {
    return this.orgPermissions;
  }

  getSitePermissions(): string[] {
    return this.sitePermissions;
  }

  getUserId(): string {
    return this.user.id;
  }

  isOrgAdmin(): boolean {
    return this.orgAdmin;
  }

  isOrgViewer(): boolean {
    return this.orgViewer;
  }

  isSiteAdmin(): boolean {
    return this.siteAdmin;
  }

  isSiteViewer(): boolean {
    return this.siteViewer;
  }

  isEngineer(): boolean {
    return this.engineer;
  }

  isInstaller(): boolean {
    return this.installer;
  }
}
