import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'atom-config-subsection-header',
  templateUrl: './config-subsection-header.component.html',
  styleUrls: ['./config-subsection-header.component.scss']
})
export class ConfigSubsectionHeaderComponent {
  @Input() subsectionTitle!: string;
  @Input() subsectionBody!: string;
  @Input() buttonVisible: boolean = false;
  @Input() buttonText!: string;
  @Input() buttonIcon!: string;
  @Output() buttonClicked = new EventEmitter<void>();

  buttonClick() {
    this.buttonClicked.emit();
  }
}
