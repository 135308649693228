<div *ngIf="visible" class="configuration-multi-input-container">
  <div id="save-discard-button-bar" class="flex flex-column align-items-end align-self-stretch flex-grow-1 w-full">
    <div class="flex gap-4">
      <div class="button-gap">
        <p-button styleClass="flex align-items-center gap-2 text-sm font-semibold p-button-secondary min-w-max h-full"
                  [label]="declineButton"
                  (onClick)="declineClicked()">

        </p-button>
        <p-button styleClass="flex min-w-max h-full"
                  [disabled]="disabled"
                  [label]="acceptButton"
                  (onClick)="acceptClicked()">

        </p-button>
      </div>
    </div>
  </div>
</div>
