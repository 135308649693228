<ng-template #warningIcon>
  <div class="relative w-4rem h-4rem">
    <div class="warning-circle-icon"></div>
    <div class="warning-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #infoIcon>
  <div class="relative w-4rem h-4rem">
    <div class="info-circle-icon"></div>
    <div class="info-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #errorIcon>
  <div class="relative w-4rem h-4rem">
    <div class="error-circle-icon"></div>
    <div class="error-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #goodIcon>
  <div class="relative w-4rem h-4rem">
    <div class="good-circle-icon"></div>
    <div class="good-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<div [class]="'widget-container ' + containerStyleClass" >
  <div [class]="'widget-container-content ' + contentStyleClass">

    <!-- Header -->
    <ng-container *ngIf="headerTemplate">
      <ng-container [ngTemplateOutlet]="headerTemplate"/>
    </ng-container>
    <ng-container *ngIf="!headerTemplate">
      <div class="header-container"
           [ngClass]="{
              'header-container-wrap': rightHeaderTemplate!=undefined,
           }">
        <span *ngIf="widgetState == WidgetState.Good" class="header-text">
          {{ headerText }}
        </span>
        <ng-template #defaultHeaderRight/>
        <ng-container *ngIf="widgetState == WidgetState.Good"
                      [ngTemplateOutlet]="rightHeaderTemplate || defaultHeaderRight"/>
      </div>
    </ng-container>

    <!-- Body -->
    <ng-container *ngIf="bodyTemplate">
      <ng-container [ngTemplateOutlet]="bodyTemplate"/>
    </ng-container>

    <ng-container *ngIf="customMessage && !bodyTemplate">
      <div class="body-content-container">
        <div class="flex flex-column justify-content-center align-items-center gap-4">
          <ng-container [ngSwitch]="widgetState">
            <ng-container *ngSwitchCase="WidgetState.Good" [ngTemplateOutlet]="goodIcon"/>
            <ng-container *ngSwitchCase="WidgetState.Info" [ngTemplateOutlet]="infoIcon"/>
            <ng-container *ngSwitchCase="WidgetState.Warning" [ngTemplateOutlet]="warningIcon"/>
            <ng-container *ngSwitchCase="WidgetState.Error" [ngTemplateOutlet]="errorIcon"/>
          </ng-container>
          <div class="flex flex-column align-items-center align-self-stretch body-outer-text-container gap-6">
            <div class="flex flex-column align-items-center align-self-stretch body-middle-text-container gap-4">
              <div class="flex flex-column align-items-center align-self-stretch body-inner-text-container gap-1">
                <div class="flex flex-column">
                  <ng-container *ngFor="let content of customMessageHeader">
                <span
                  class="align-self-stretch text-center text-base font-semibold primary-text-color">{{ content }}</span>
                  </ng-container>
                </div>
                <div class="flex flex-column">
                  <ng-container *ngFor="let content of customMessageContent">
                  <span
                    class="align-self-stretch text-center text-sm font-normal bottom-text custom-spacing secondary-text-color">{{ content }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!customMessage && !bodyTemplate">
      <div *ngIf="widgetState == WidgetState.Good" class="body-content-container">
        <ng-content></ng-content>
      </div>
      <div *ngIf="widgetState == WidgetState.Info" class="body-content-container info-state-sizing">
        <atom-widget-info [widgetTitle]="errorHeaderText ? errorHeaderText :headerText"
                          [infoMessage]="infoMessage"></atom-widget-info>
      </div>
      <div *ngIf="widgetState == WidgetState.Loading" class="flex body-content-container">
        <p-progressSpinner
          [style]="{ width: '100px', height: '100px', color: 'purple'}">
        </p-progressSpinner>
      </div>
      <div *ngIf="widgetState == WidgetState.Error" class="body-content-container">
        <atom-widget-error
          [widgetTitle]="errorHeaderText ? errorHeaderText : headerText"
          [customErrorMessage]="customMessage"
          [customHeaders]="customMessageHeader"
          [customContent]="customMessageContent"/>
      </div>
    </ng-container>

    <!-- Footer -->
    <div *ngIf="!disableFooter" class="footer-container">
      <div class="footer-text-container">
      <span *ngIf="widgetState == WidgetState.Good" class="footer-text">
        <ng-template #defaultFooter>
        </ng-template>
        <ng-container [ngTemplateOutlet]="footerTemplate || defaultFooter">
        </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
