import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AtomFormInput} from "@core/interfaces/forms/form";
import {AtomCalendarStyle, AtomFormCalendarOpts} from "@core/interfaces/forms/form-input-calendar";

@Component({
  selector: 'atom-form-calendar',
  templateUrl: './form-calendar.component.html',
  styleUrls: ['./form-calendar.component.scss']
})
export class FormCalendarComponent implements OnInit, AtomFormInput {
  @Input() required: boolean = false;
  @Input() editable: boolean = true;

  @Input() calendarOpts!: AtomFormCalendarOpts;

  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  /**
   * calendarStyle
   *
   * The style of the calendar to be displayed.
   *
   * Ref: {@link AtomCalendarStyle}
   */
  @Input({required: true}) calendarStyle!: AtomCalendarStyle;

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInput: EventEmitter<any> = new EventEmitter<any>();

  protected calendarSelectedValue: string = '';

  /////////////////////////////
  // Calendar Style Options

  protected _showTime: boolean = false;
  protected _timeOnly: boolean = false;

  /////////////////////////////
  // Calendar Options

  protected _description?: string;

  protected _placeholder?: string;

  protected _readOnlyInput: boolean = true;

  // button bar settings
  protected _buttonBar: boolean = false;
  protected _clearButtonStyle: string = '';
  protected _todayButtonStyle: string = '';

  protected _defaultDate!: Date;
  protected _minDate!: Date;

  // is range
  protected _selectionMode: 'single' | 'multiple' | 'range' | undefined = 'single';

  // time selection
  protected _hourFormat: string = '12';
  protected _stepMinute: number = 15;

  nonEditLabelOverride: string = '-';

  ngOnInit(): void {
    this.parseOpts();
    this.setCalendarStyle();
  }

  private setCalendarStyle() {
    switch (this.calendarStyle) {
      case AtomCalendarStyle.Date:
        if (this.group.controls[this.controlName].value) {
          this.calendarSelectedValue = this.group.controls[this.controlName].value.toLocaleDateString();
        }
        break;
      case AtomCalendarStyle.Time:
        if (this.group.controls[this.controlName].value) {
          this.calendarSelectedValue = this.group.controls[this.controlName].value.toTimeString();
        }
        this._showTime = true;
        this._timeOnly = true;
        break;
      case AtomCalendarStyle.DateTime:
        if (this.group.controls[this.controlName].value) {
          this.calendarSelectedValue = this.group.controls[this.controlName].value.toLocaleDateString() + ' ' + this.group.controls[this.controlName].value.toTimeString();
        }
        this._showTime = true;
        break;
    }
  }

  private parseOpts() {
    if (!this.calendarOpts) {
      this.calendarOpts = {}
    }

    this._description = this.calendarOpts.description ? this.calendarOpts.description : undefined;
    this._placeholder = this.calendarOpts.placeholder;

    this._buttonBar = this.calendarOpts.buttonBar ?? this._buttonBar;
    this._clearButtonStyle = this.calendarOpts.hideClearButton ? 'hidden' : '';
    this._todayButtonStyle = this.calendarOpts.hideTodayButton ? 'hidden' : '';

    this._defaultDate = this.calendarOpts.defaultDate ?? new Date();
    this._minDate = this.calendarOpts.minDate ?? new Date(2016,0,1,0,0,0);

    this._selectionMode = this.calendarOpts.dateRange ? 'range' : 'single';

    this._hourFormat = this.calendarOpts.hourFormat ?? this._hourFormat;
    this._stepMinute = this.calendarOpts.stepMinute ?? this._stepMinute;

    this.nonEditLabelOverride = this.calendarOpts.nonEditLabelOverride ?? this.nonEditLabelOverride;

    this._readOnlyInput = this.calendarOpts.readOnlyInput ?? this._readOnlyInput;
  }

  ////////////////////////////////////////
  //  Event Emitters

  onDateSelect(event: any) {
    this.onSelect.emit(event);
  }

  onDateInput(event: any) {
    this.onInput.emit(event);
  }
}
