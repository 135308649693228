import {
  Component, ComponentRef,
  EventEmitter,
  HostListener, inject,
  Input, OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {WidgetState} from "@core-shared/components/widget-container/widget-state";
import {ChartsService, LineChartTemplate} from "@core-shared/services/charts/charts.service";
import * as Highcharts from "highcharts";
import {HighchartsChartComponent} from "highcharts-angular";
import {Calendar} from "primeng/calendar";
import {DateRange} from "@models/analytics.model";
import {TableTemplatesComponent} from "@core-shared/templates/table-templates/table-templates.component";
import {CustomTableViewContext, SupportedPadding, TemplateType} from "@models/table.model";

@Component({
  selector: 'atom-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  private tabletWidth: number = 990;
  private lastWidth: number = 0;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////                                                                                                              ////
  ////                                                                                                              ////
  ////                                             Right Header Template                                            ////
  ////                                                                                                              ////
  ////                                                                                                              ////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Optional Input:
  //
  // True will show content defined with {@link rightHeaderTemplate} in the top right of the widget header
  @Input() rightHeaderVisible: boolean = false;

  // Optional Input:
  //
  // Template to be displayed in the top right of the widget header, will only appear if {@link rightHeaderVisible} is true.
  //
  // LineChartTemplate enum is defined here {@link LineChartTemplate}
  @Input() rightHeaderTemplateEnum: LineChartTemplate = LineChartTemplate.None;

  protected rightHeaderTemplate!: TemplateRef<any>;


  //////////////////////////////////////////////////////////
  ////              Date Selector Template              ////
  //////////////////////////////////////////////////////////

  @ViewChild('dateSelector') protected set dateSelectorRef(value: TemplateRef<any>) {
    this._dateSelectorRef = value;

    if (this.rightHeaderTemplateEnum){
      if (this.rightHeaderTemplateEnum == LineChartTemplate.DateSelector) {
        this.rightHeaderTemplate = value;
      }
    }
  };
  get dateSelectorRef(): TemplateRef<any> {
    return this._dateSelectorRef;
  };
  private _dateSelectorRef!: TemplateRef<any>;

  protected set templateDateRange(value: Date[]) {
    if (value.length == 2) {
      this.filterDateRange = {startDate: value[0], endDate: value[1]};
      this._templateDateRange = value;
    }
  }
  get templateDateRange(): Date[] {
    return this._templateDateRange;
  }
  private _templateDateRange!: Date[];

  @Input() set filterDateRange(value: DateRange) {
    if (value) {
      if (value.startDate && value.endDate) {
        this._filterDateRange = value;
        this.filterDateRangeChange.emit(this.filterDateRange);
      }
    }
  }
  get filterDateRange(): DateRange {
    return this._filterDateRange;
  }
  private _filterDateRange!: DateRange;
  @Output() filterDateRangeChange = new EventEmitter<DateRange>();

  protected disableDate!: Array<Date>;
  private calenderSelectCount: number = 0;

  protected maxDate: Date = new Date();

  @ViewChild('calendar') protected calendar!: Calendar;

  protected selectedTimeframe: string = 'today';
  protected timeframeOptions: any = [
    {label: 'Today', value: 'today'},
    {label: 'Week to Date', value: 'week_to_date'},
    {label: 'Month to Date', value: 'month_to_date'},
    {label: 'Quarter to Date', value: 'quarter_to_date'},
    {label: 'Year to Date', value: 'year_to_date'},
    {label: 'Custom', value: 'custom'}
  ];

  @Output() timeframeChanged = new EventEmitter<string>();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////                                                                                                              ////
  ////                                                                                                              ////
  ////                                                 Widget Info                                                  ////
  ////                                                                                                              ////
  ////                                                                                                              ////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Required Input:
  //
  // State of the widget, initialized to WidgetState.Loading.
  @Input({required: true}) chartWidgetState: WidgetState = WidgetState.Loading;

  @Input({required: true}) headerText!: string;

  initialized: boolean = false;

  // Variables to inject templates into the DOM
  vcr: ViewContainerRef = inject(ViewContainerRef);
  compRef!: ComponentRef<TableTemplatesComponent>;

  @Input() noData: boolean = false;

  noDataInfoViewContext: CustomTableViewContext = {
    padding: SupportedPadding.Padding90,
    templateType: TemplateType.Info,
    customHeader: 'Data Not Found',
    customContent: ['No data found for given Date Range, please select a different Date Range'],
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////                                                                                                              ////
  ////                                                                                                              ////
  ////                                              Highchart Settings                                              ////
  ////                                                                                                              ////
  ////                                                                                                              ////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  protected chartUpdate: boolean = false;
  protected highcharts: typeof Highcharts = Highcharts;
  @ViewChild('chart') chart !: HighchartsChartComponent;

  // Required Input:
  //
  // Highcharts options object that differ from the default options for Atom Power Line Charts.
  // See setLineChart() below for the default options.
  @Input({required: true})
  get specificChartOptions(): Highcharts.Options{
    return this._specificChartOptions;
  }
  set specificChartOptions(value: Highcharts.Options) {
    this._specificChartOptions = value;

    if (this.seriesInitialized) {
      this.setLineChart();
      this.initialized = true;
      this.chartUpdate = true;
    } else {
      this.chartOptionsInitialized = true;
    }
  }
  private _specificChartOptions!: Highcharts.Options;
  private chartOptionsInitialized: boolean = false;

  protected chartOptions!: Highcharts.Options;
  protected xAxisPositioner!: Highcharts.AxisTickPositionerCallbackFunction;
  private xAxisFormatter!: Highcharts.AxisLabelsFormatterCallbackFunction;

  // Required Input:
  //
  // Array of series data to be rendered on the chart.
  @Input({required: true})
  get seriesData(): Highcharts.SeriesOptionsType[] {
    return this._seriesData;
  }
  set seriesData(value: Highcharts.SeriesOptionsType[]) {
    this._seriesData = value;

    if (this.chartOptionsInitialized) {
      this.xAxisFormatter = this.chartService.GetXAxisLabelFormat(this.filterDateRange);
      this.setLineChart();
      this.initialized = true;
      this.chartUpdate = true;
    } else {
      this.seriesInitialized = true;
    }
  }
  private _seriesData!: Highcharts.SeriesOptionsType[];

  private seriesInitialized: boolean = false;

  ////**************************************************************************************************************////
  //******************************************************************************************************************//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //**                                               Init Functions                                                 **//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //******************************************************************************************************************//
  ////**************************************************************************************************************////

  constructor(private chartService: ChartsService) {}

  ngOnInit(): void {
    this.setXAxisPositioner(window.innerWidth);
    this.compRef = this.vcr.createComponent(TableTemplatesComponent);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setXAxisPositioner(event.target.innerWidth);
  }


  ////**************************************************************************************************************////
  //******************************************************************************************************************//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //**                                               Filter Functions                                               **//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //******************************************************************************************************************//
  ////**************************************************************************************************************////

  selectedDates() {
    if (this.templateDateRange?.length == 2) {
      this.filterDateRange = {startDate: this.templateDateRange[0], endDate: this.templateDateRange[1]};

      this.maxDate = new Date();
    }
  }

  disableSameDaySelect(date: Date) {
    if (this.disableDate?.length > 0 && date < this.disableDate[0]) {
      this.disableDate.pop();
      this.disableDate.push(date);
      this.calenderSelectCount = 0;
    }

    if (this.calenderSelectCount === 0) {
      this.disableDate = [];
      this.disableDate.push(date);
      this.calenderSelectCount++;
    } else {
      this.disableDate.pop();
      this.calenderSelectCount = 0;
    }

  }

  timeframeChange() {
    let startDate = new Date();
    let endDate = new Date();

    switch (this.selectedTimeframe) {
      case 'today':
        startDate.setHours(0,0,0,0);
        break;
      case 'week_to_date':
        startDate.setDate(endDate.getDate() - (endDate.getDay() === 0 ? 6 : endDate.getDay() - 1));
        startDate.setHours(0, 0, 0, 0);
        break;
      case 'month_to_date':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1, 0,0,0,0);
        break;
      case 'quarter_to_date':
        startDate = new Date(endDate.getFullYear(), Math.floor((endDate.getMonth() / 3)) * 3, 1, 0,0,0,0);
        break;
      case 'year_to_date':
        startDate = new Date(endDate.getFullYear(), 0, 1,0,0,0);
        break;
      case 'custom':
        return;
    }
    this.filterDateRange = {startDate: startDate, endDate: endDate};

    this.maxDate = endDate;

    this.timeframeChanged.emit(this.selectedTimeframe);
  }

  ////**************************************************************************************************************////
  //******************************************************************************************************************//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //**                                       Chart Option Setting Functions                                         **//
  //**                                                                                                              **//
  //**                                                                                                              **//
  //******************************************************************************************************************//
  ////**************************************************************************************************************////

  setXAxisPositioner(width: number) {
    let change: boolean = false;

    if (width <= this.tabletWidth && (this.lastWidth > this.tabletWidth || this.lastWidth == 0)) {
      this.xAxisPositioner = this.chartService.FourXAxisPositioner();
      change = true;
    } else if (width > this.tabletWidth && (this.lastWidth <= this.tabletWidth || this.lastWidth == 0)) {
      this.xAxisPositioner = this.chartService.SevenXAxisPositioner();
      change = true;
    }

    if (change) {
      this.setLineChart();
      if (this.chart) {
        this.chart.update = true;
      }
    }
  }

  private setLineChart() {
    const defaultChartOptions: Highcharts.Options = {
      chart: {
        type: 'line',
        backgroundColor: 'var(--bg-surface-color)',
        height: 278,
        zooming: {
          type: 'x'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        line: {
          turboThreshold: 0,
          threshold: null,
          states: {
            hover: {
              enabled: true,
              lineWidth: 2
            },
          }
        },
        series: {
          getExtremesFromAll: true,
          marker: { // marker on hover
            enabled: false,
            fillColor: 'var(--primary-500)',
            lineColor: 'var(--nav-item)',
          }
        }
      },
      series: this.seriesData,
      title: {
        text: ''
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        style: {
          color: 'var(--map-text-color)'
        },
        backgroundColor: 'var(--map-popup-color)',
        headerFormat: ``,
        footerFormat: ''
      },
      xAxis: {
        type: 'datetime',
        labels: {
          enabled: true,
          formatter: this.xAxisFormatter,
          style: {
            color: 'var(--primary-color-text)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '0.75rem',
            fontWeight: '400'
          }
        },
        startOnTick: true,
        endOnTick: true,
        lineWidth: 1,
        lineColor: 'var(--primary-color-text)',
        tickWidth: 1,
        tickLength: 15,
        tickColor: 'var(--primary-color-text)',
        tickPosition: 'outside',
        crosshair: {
          color: 'var(--surface-700)',
          width: 1
        },
        tickPositioner: this.xAxisPositioner
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: true,
          style: {
            color: 'var(--primary-color-text)',
            textAlign: 'right',
            fontSize: '0.75rem',
            fontFamily: 'Inter',
            fontWeight: '400',
            fontStyle: 'normal'
          }
        },
        lineWidth: 0,
        gridLineWidth: 1,
        minorGridLineWidth: 0,
        gridLineColor: 'var(--line-chart-line-color)',
        lineColor: 'var(--primary-color-text)',
      },
    }

    if (this.specificChartOptions) {
      this.chartOptions = Highcharts.merge(defaultChartOptions, this.specificChartOptions);
    } else {
      this.chartOptions = defaultChartOptions;
    }
  }

  protected readonly WidgetState = WidgetState;
}
