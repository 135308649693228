import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AtomFormInput, InputType} from "@core/interfaces/forms/form";
import {AtomDropdownStyle, AtomFormDropdownOpts} from '@core/interfaces/forms/form-input-dropdown';

@Component({
  selector: 'atom-form-dropdown-input',
  templateUrl: './form-dropdown-input.component.html',
  styleUrls: ['./form-dropdown-input.component.scss']
})
export class FormDropdownInputComponent implements OnInit, AtomFormInput {

  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                             Base form input requirements                             //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  @Input() required: boolean = false;
  @Input({required: true}) group!: FormGroup<any>;
  @Input({required: true}) controlName!: string;
  @Input({required: true}) fieldName!: string;
  @Input() editable: boolean = true;

  //////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                      //
  //                       Dropdown Input specific Inputs/Outputs                         //
  //                                                                                      //
  //////////////////////////////////////////////////////////////////////////////////////////

  /**
   * options
   *
   * The array of options to be displayed in the dropdown
   * */
  @Input({required: true}) options!: any[];

  /**
   * dropdownOptions
   *
   * Additional options for the AtomFormDropdownInput component to expand functionality. See {@link AtomFormDropdownOpts}
   * */
  @Input() dropdownOpts!: AtomFormDropdownOpts;

  /**
   * dropdownChange
   *
   * Event emitter for when the dropdown selection changes
   */
  @Output() dropdownChange: EventEmitter<any> = new EventEmitter<any>();

  protected label!: string;
  protected value!: string;
  protected placeholder!: string;

  ngOnInit(): void {
    if (this.dropdownOpts) {
      if (this.dropdownOpts.label) {
        this.label = this.dropdownOpts.label;
      }
      if (this.dropdownOpts.placeholder) {
        this.placeholder = this.dropdownOpts.placeholder;
      }
      if (this.dropdownOpts.value) {
        this.value = this.dropdownOpts.value;
      }
    } else {
      this.dropdownOpts = {
        label: '',
        placeholder: 'Select an option'
      };
    }
  }

  onDropdownChange(event: any): void {
    this.dropdownChange.emit(event);
  }

  protected readonly InputType = InputType;
  protected readonly AtomDropdownStyle = AtomDropdownStyle;
}
