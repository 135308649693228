import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ConfirmationModalService } from '@core-shared/services/confirmation-modals/confirmation-modal.service';
import DialogMessages from "@constants/dialog.json";
import {DialogRefConfig} from "@models/dialog";

@Component({
  selector: 'atom-form-button-bar',
  templateUrl: './form-button-bar.component.html',
  styleUrls: ['./form-button-bar.component.scss']
})
export class FormButtonBarComponent implements OnInit {
  protected readonly dialogMessages = DialogMessages;

  @Input() disabled: boolean = false;
  @Input() visible: boolean = false;

  @Input() dialogMessage: DialogRefConfig = this.dialogMessages.DISCARD_CHANGES

  @Input() declineButton: string = 'Discard Changes';
  @Input() acceptButton: string = 'Save Changes';

  @Output() declineEvent = new EventEmitter<string>();
  @Output() acceptEvent = new EventEmitter<string>();

  declineModalVisibly: boolean = false;

  constructor(private confirmationModalService: ConfirmationModalService) {}

  ngOnInit() {
  }

  declineClicked() {
    this.confirmationModalService.BuildAndOpenConfirmationModal(this.dialogMessages.DISCARD_CHANGES).subscribe({
      next: (data) => { // closed the dialog
        if (data != undefined) { // we didnt click the x
          if (!data) {
            this.declineEvent.emit();
          }
        }
      }
    })
  }

  acceptClicked() {
    this.acceptEvent.emit();
  }

}
