import {Component, Input} from '@angular/core';

@Component({
  selector: 'atom-widget-info',
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.scss']
})
export class WidgetInfoComponent {
  @Input() widgetTitle!: string;
  @Input() infoMessage!: string;
  @Input() customInfoMessage = false;
  @Input() messageHeader = '';
  @Input() messageContent = '';
}
