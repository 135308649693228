<atom-form-input
  [required]="required"
  [group]="group"
  [controlName]="controlName"
  [fieldName]="fieldName">
  <form
    [formGroup]="group" class="flex w-full flex-column">
    <p-checkbox [class]="contentStyle" formControlName="stable" [binary]="true" inputId="binary"/>
  </form>
</atom-form-input>
