<div class="flex w-full justify-content-center">
  <div role="tab"
       *ngFor="let step of steps; index as i; first as isFirst; last as isLast"
       class="flex flex-column align-items-center gap-14px flex-grow-1">
    <div class="flex align-items-center gap-2 align-self-stretch">
      <div class="flex step-line-height step-line-bg flex-grow-1" [ngClass]="{'step-line-bg-first-last': isFirst, 'step-line-active-bg': !isFirst && currentStep >= i}"></div>
      <div *ngIf="currentStep <= i" class="flex justify-content-center align-items-center step-circle" [ngClass]="{'step-circle-active': i == currentStep}">
        <span class="material-symbols-sharp icon-color cursor-default" [ngClass]="{'icon-color-active': i == currentStep}">{{step.icon}}</span>
      </div>
      <div *ngIf="currentStep > i" class="flex justify-content-center align-items-center step-circle-completed" [ngClass]="{'step-circle-active': i == currentStep}">
        <span class="material-symbols-sharp icon-color-completed cursor-default">done</span>
      </div>
      <div class="flex step-line-height step-line-bg flex-grow-1" [ngClass]="{'step-line-bg-first-last': isLast, 'step-line-active-bg': !isLast && currentStep > i}"></div>
    </div>
    <div class="flex flex-column gap-1">
      <div class="step-text">
        Step {{i+1}}
      </div>
      <div class="step-title">
        {{step.title}}
      </div>
    </div>
  </div>
</div>
