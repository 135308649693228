import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
const Highcharts = require('highcharts/highstock');
import { WidgetState } from '../widget-container/widget-state';
import { MapPopupService } from '@core-shared/services/map-popup/map-popup.service';
import { Subscription } from 'rxjs';
import { PieChartData } from '@core-shared/models/chart.models';

@Component({
  selector: 'atom-analytics-chart-widget',
  templateUrl: './analytics-chart-widget.component.html',
  styleUrls: ['./analytics-chart-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AnalyticsChartWidgetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('chart') chart !: any;
  @ViewChild('selectButton') selectButton!: any;
  @Input() incomingData: any;
  @Input() incomingHeaderText!: string;
  @Input() donutTopMiddleText!: string;
  @Input() donutMiddleText!: string;
  @Input() incomingLegend!: PieChartData[];
  @Input() incomingChartType!: string;
  @Input() modifier: string = '';
  @Input() widgetState!: WidgetState;
  @Input() infoMessage!: string;
  @Input() barChartCategory: string = 'access';
  highcharts: typeof Highcharts = Highcharts;

  chartUpdate: boolean = false;
  chartOptions!: Highcharts.Options;
  headerFormatText!: string;
  backgroundColor!: string;
  pointFormat!: string;
  format!: string;
  headerText: string = 'Usage By Access Group';
  errorHeaderText = '';
  namesArr: string[] = [];

  options: any[] = [{ label: 'Usage', value: 'usage' }, { label: '# Sessions', value: 'sessions' }]
  maxEntries = 11;
  gradientColors = {
    purple: 'var(--analytics-gradient-purple, #ffffff)',
    blue: 'var(--analytics-gradient-blue, #ffffff)',
    lightblue: 'var(--analytics-gradient-lightblue, #ffffff)',
  }

  data!: number[];

  donutToolTipText!: string;
  subscriptions$ = new Subscription();
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.incomingData?.useBarChart) {
      this.barChartSizing(event.target.innerWidth);
      const chartDataSource = (this.incomingChartType == 'usage' ? JSON.parse(JSON.stringify(this.incomingData.usage)) : JSON.parse(JSON.stringify(this.incomingData.sessions)))
      this.setBarChartOptions(chartDataSource);
      this.chartUpdate = true;
    }
  }
  constructor(private popupService: MapPopupService) {
    this.subscriptions$.add(
      this.popupService.getEvent().subscribe(() => {
        this.chart.chart?.tooltip?.hide(0);
      })
    )
  }

  ngOnInit() {
    if (this.incomingData?.useBarChart) {
      this.setBarChart();
    } else {
      this.setDonutChart();
    }
  }

  ngAfterViewInit() {
    if (this.incomingData?.useBarChart) {
      this.setHeaderBasedOnCategory();
      this.barChartSizing(window.innerWidth);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.incomingData){
      if (this.incomingData?.useBarChart) {
        this.setBarChart();
      } else {
        this.setDonutChart();
      }
    }
  }

  updateChartDataSource() {
    this.setBarChart();
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  setBarChart() {
    const chartDataSource = (this.incomingChartType == 'usage' ? JSON.parse(JSON.stringify(this.incomingData.usage)) : JSON.parse(JSON.stringify(this.incomingData.sessions)))
    this.getIncomingNames();
    this.setChartTextOptions();
    this.setHeaderBasedOnCategory();
    this.setChartMaxEntries(window.innerWidth);
    this.setBarChartOptions(chartDataSource);
    this.chartUpdate = true;
  }

  setDonutChart() {
    this.setDonutChartTextOptions();
    this.setDonutChartOptions();
    this.chartUpdate = false;
  }

  setChartMaxEntries(width: any) {
    if (width > 1000) {
      this.namesArr.length >= 12 ? this.maxEntries = 11 : this.maxEntries = (this.namesArr.length - 1);
    } else {
      this.maxEntries = 4;
    }
    if (this.chart) {
      this.chart?.chart?.xAxis[0].setExtremes(0, this.maxEntries);
    }
  }

  barChartSizing(width: any) {
    const bar: any = document.getElementById('bar-chart-' + this.barChartCategory);
    const barContainer: any = bar?.getElementsByTagName("div");
    const customWidth = width - 400;
    barContainer[0].setAttribute("style", "width:" + customWidth + "px");
    this.setChartMaxEntries(width);
  }

  setDonutChartTextOptions() {
    if (this.incomingChartType == "usage") {
      this.donutToolTipText = '<div class="tooltip-text" style="margin-top: 10px"><span>{point.y} kWh</span><br /><span> {point.percent}% of Total Usage</span></div>';
    } else if (this.incomingChartType == 'sessions') {
      this.donutToolTipText = '<div class="tooltip-text" style="margin-top: 10px"><span>{point.y} Sessions</span><br /><span> {point.percent}% of Total Sessions</span></div>';
    } else if (this.incomingChartType == 'uptime') {
      this.donutToolTipText = '<div style="margin-top: 10px"><span>{point.y}%</span></div>';
    }
  }

  setChartTextOptions() {
    if (this.incomingChartType === 'usage') {
      this.headerFormatText = '<div class="tooltip-text">{point.key}</div>';
      this.backgroundColor = "var(--map-popup-color,#ffffff)";
      this.pointFormat = '<div class="tooltip-text" style="margin-top: 10px"><span>{point.y} kWh</span><br /><span>{point.percent}% of Total Usage</span></div>';
      this.format = '{text} kWh';
    } else {
      this.headerFormatText = '<div class="tooltip-text">{point.key}</div>';
      this.backgroundColor = "var(--map-popup-color,#ffffff)";
      this.pointFormat = '<div class="tooltip-text" style="margin-top: 10px"><span>{point.y} Sessions</span><br /><span>{point.percent}% of Total Sessions</span></div>';
      this.format = '{text}';
    }
  }

  findGraphQuadrant(x: number, y: number): number {
    let quadrant = 0;

    if (y >= 75) {
      quadrant = 3;
    } else {
      quadrant = 1;
    }

    if (x >= 75) {
      quadrant = quadrant + 1;
    }

    return quadrant;
  }

  setDonutChartOptions() {
    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: 0,
        plotShadow: false,
        margin: 0,
        backgroundColor: undefined,
      },
      title: {
        text: undefined
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          },
        },
        pie: {
          zIndex: 2,
          animation: true,
          dataLabels: {
            enabled: false
          }
        }
      },
      xAxis: {
        crosshair: false
      },
      tooltip: {
        positioner: (labelWidth, labelHeight, point: Highcharts.TooltipPositionerPointObject) => {
          let q = this.findGraphQuadrant(point.plotX, point.plotY)
          let x = 0
          let y = 0

          switch (q) {
            case 1:
              x = point.plotX - (labelWidth);
              y = point.plotY - (labelHeight);
              break;
            case 2:
              x = point.plotX;
              y = point.plotY - (labelHeight);
              break;
            case 3:
              x = point.plotX - (labelWidth);
              y = point.plotY;
              break;
            case 4:
              x = point.plotX;
              y = point.plotY;
              break;
          }

          return { x, y }
        },
        shape: "square",
        animation: false,
        outside: true,
        useHTML: true,
        headerFormat: '<div style="max-width:184px; text-overflow:ellipsis; white-space:nowrap; overflow: hidden;"><span style="color: {point.color}">\u25CF</span> {point.key}</div>',
        backgroundColor: "var(--map-popup-color,#ffffff)",
        pointFormat: this.donutToolTipText,
        style: {
          color: "#ffffff",
          fontWeight: "normal",
          fontFamily: "Inter",
          fontSize: ".85rem"
        }
      },
      series: [{
        type: 'pie',
        enableMouseTracking: true,
        borderRadius: 0,
        borderWidth: 0,
        zIndex: 3,
        innerSize: 110,
        stickyTracking: false,
        tooltip: {
          followPointer: false,
        },
        data: this.incomingData
      }]
    };
  }

  setBarChartOptions(chartDataSource: any) {
    this.chartOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'var(--bg-surface-color)',
        zooming: {
          mouseWheel: false
        },
        // @ts-ignore
        panning: true
      },
      title: {
        text: undefined,
      },
      xAxis: {
        categories: this.namesArr,
        crosshair: false,
        labels: {
          rotation: 320,
          style: {
            color: 'var(--primary-color-text)',
            textOverflow: 'ellipsis',
            width: 100
          }
        },
        gridLineColor: 'var(--table-border-right)',
        scrollbar: {
          enabled: true
        },
        panningEnabled: true,
        max: this.maxEntries
      },
      yAxis: {
        title: {
          text: undefined
        },
        labels: {
          format: this.format,
          style: {
            color: 'var(--primary-color-text)',
            margin: '0 2rem'
          }
        },
        gridLineColor: 'var(--table-border-right)'
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shape: "square",
        animation: false,
        outside: true,
        useHTML: true,
        headerFormat: this.headerFormatText,
        backgroundColor: this.backgroundColor,
        pointFormat: this.pointFormat,
        style: {
          color: "#ffffff",
          fontWeight: "normal",
          fontFamily: "Inter",
          fontSize: ".85rem"
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          pointWidth: 40,
          borderWidth: 0,
          states: {
            hover: {
              enabled: false
            },
          }
        },
      },
      scrollBar: {
        enabled: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: 'column',
          name: undefined,
          legendSymbol: undefined,
          color: {
            // @ts-ignore
            linearGradient: [0, 0, 0, 400],
            stops: [
              [0, this.gradientColors.purple],
              [0.5, this.gradientColors.blue],
              [1, this.gradientColors.lightblue]

            ],

          },
          borderRadius: {
            radius: 4,
            scope: 'point',
            where: 'end'
          },
          borderWidth: 0,
          zIndex: 3,
          stickyTracking: false,
          tooltip: {
            followPointer: false,
          },
          data: chartDataSource
        }
      ]
    };
  }

  getIncomingNames() {
    this.namesArr = [];
    if (this.incomingChartType === 'usage') {
      this.incomingData?.usage.forEach((data: any) => {
        this.namesArr.push(data.name.toString());
      });
    }
    else {
      this.incomingData?.sessions.forEach((data: any) => {
        this.namesArr.push(data.name.toString());
      });
    }
  }

  setHeaderBasedOnCategory() {
    if (this.barChartCategory === 'access') {
      this.headerText = (this.incomingChartType === 'usage' ? 'Usage By Access Group' : 'Sessions By Access Group')
      this.errorHeaderText = 'Access Group Usage and # Sessions';
    } else if (this.barChartCategory === 'site') {
      this.headerText = (this.incomingChartType === 'usage' ? 'Usage By Site' : 'Sessions By Site')
      this.errorHeaderText = 'Site Usage and # Sessions';
    } else if (this.barChartCategory === 'charger') {
      this.headerText = (this.incomingChartType === 'usage' ? 'Usage By Charger' : 'Sessions By Charger')
      this.errorHeaderText = 'Charger Usage and # Sessions';
    }
  }
}
