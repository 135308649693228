import {Component, TemplateRef, ViewChild} from '@angular/core';
import {SupportedPadding, TemplateType} from '@core-shared/models/table.model';

@Component({
  selector: 'atom-table-templates',
  templateUrl: './table-templates.component.html',
  styleUrls: ['./table-templates.component.scss']
})
export class TableTemplatesComponent{

  @ViewChild('customTableStateTemplate') customTableStateTemplate!: TemplateRef<any>;
  @ViewChild('customStateTemplate') customStateTemplate!: TemplateRef<any>;
  @ViewChild('paginatorNext') paginatorNext!: TemplateRef<any>;
  @ViewChild('paginatorPrevious') paginatorPrevious!: TemplateRef<any>;
  @ViewChild('sortIcon') sortIcon!: TemplateRef<any>;

  protected readonly SupportedPadding = SupportedPadding;
  protected readonly TemplateType = TemplateType;
}
