<ng-template #siteCountHeader>
  <span *ngIf="showSiteCount">{{siteCount}} Sites</span>
</ng-template>

<ng-template #statusFooterLink>
  <!--suppress HtmlUnknownAnchorTarget -->
  <a class="url-text text-decoration cursor-pointer" (click)="navToTable()">{{statusFooterText}}</a>
</ng-template>

<atom-widget-container
  [rightHeaderTemplate]="siteCountHeader"
  [widgetState]="chargerStatusState"
  [headerText]="chargerStatusHeaderText"
  [footerTemplate]="statusFooterLink">
  <div *ngIf="chargerStatus && chartOptions" class="charger-status-body-container">
    <div class="charger-status-donut-container">
      <div class="border-container">
        <svg class="svg-border" width="149" height="149" viewBox="0 0 204 203" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="103" cy="102.5" r="55" stroke-width="15"/>
          <circle cx="103" cy="101.5" r="90" stroke-width="15"/>
        </svg>
      </div>
      <highcharts-chart #chart [Highcharts]="highcharts" [(update)]="chartUpdate" [options]="chartOptions"
                        style="z-index: 3; display: flex; position: absolute; width: 9.375rem; height: 9.375rem;">

      </highcharts-chart>
      <div class="donut-inner-container">
        <div class="donut-inner-text-container">
          <a class="inner-container-top-text">{{totalChargerCount}}</a>
          <a class="inner-container-bottom-text">
            Chargers
          </a>
        </div>
      </div>
    </div>
    <div class="charger-status-legend-container">
      <ng-container *ngFor="let status of chargerStatus; let i = index">
        <div *ngIf="status.y > 0" class="legend-item-container">
          <svg class="legend-color-key" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
               fill="none">
            <circle cx="6.5" cy="6.5" r="6" [attr.fill]="status.color"/>
          </svg>
          <a class="legend-text-key">({{status.y}}) {{status.name}}</a>
        </div>
      </ng-container>
    </div>
  </div>
</atom-widget-container>
