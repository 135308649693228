import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AtomFormInput, InputType, TextFormType} from "@core/interfaces/forms/form";

@Component({
  selector: 'atom-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements AtomFormInput {
  @Input() required: boolean = false;
  @Input() group!: FormGroup<any>;
  @Input() controlName!: string;
  @Input() fieldName!: string;
  @Input() editable!: boolean;

  @Input() inputType!: InputType;

  @Input() formInputStyle!: string;
  @Input() contentStyle!: string;

  /**
   * fieldCounter: boolean
   *
   * Default: false
   *
   * If true, a character counter will be displayed on the title row for the input field
   *
   * Dependants:
   *
   * - {@link maxLen}
   * */
  @Input() fieldCounter: boolean = false;
  /**
   * maxLen: number
   *
   * @Default 0
   *
   * @Description
   * If provided, the character counter will display the current length of the input field and the maximum length.
   *
   * @Requirements
   * {@link fieldCounter} must be true
   *
   * */
  @Input() maxLen: number = 0;

  protected readonly FormType = TextFormType;
  protected readonly InputType = InputType;
}
