import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';

@Component({
  selector: 'atom-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent implements OnInit {
  @Input() visible = false;
  @Output() logoutEmit = new EventEmitter();
  constructor(private auth: AuthService) {}

  ngOnInit(): void {
  }

  closeDialog() {
    this.visible = false;
    this.logoutEmit.emit();
  }

  async logout() {
    await this.auth.logout();
    this.closeDialog();
  }
}
