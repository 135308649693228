import { Component, OnInit } from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'atom-inactivity',
  templateUrl: './inactivity.component.html',
  styleUrls: ['./inactivity.component.scss']
})
export class InactivityComponent implements OnInit{
  visible = false;
  idleState: string = '';
  subscriptions$ = new Subscription();
  id: any;
  timer = environment.countdownTime;
  constructor(private auth: AuthService, private userIdle: UserIdleService) {

  }

  ngOnInit() {
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      this.visible = true;
      this.idleState = 'Due to inactivity, you will be logged out in ' + (environment.countdownTime - count) + ' sec. Do you want to stay logged in?'
    });
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.logout()
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  logout() {
    this.auth.logout();
    this.resetTimer();
  }

   resetTimer() {
    this.restart();
    this.visible = false;
  }

   closeDialog() {
    this.visible = false;
    this.resetTimer();
  }

  ngOnDestroy(): void {
    this.stop();
    this.subscriptions$.unsubscribe();
  }
}
