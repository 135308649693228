<p-dialog header="Session Expiration Warning" [(visible)]="visible" [modal]="true" [draggable]="false" [dismissableMask]="true" (onHide)="closeDialog()" [resizable]="false" [style]="{width: '700px'}">
    <ng-template pTemplate="closeicon">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <mask id="mask0_1883_6197" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
            <rect width="28" height="28" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1883_6197)">
            <path class="fill" d="M7.46677 21.7619L6.2373 20.5325L12.7706 13.9991L6.2373 7.46579L7.46677 6.23633L14.0001 12.7697L20.5334 6.23633L21.7629 7.46579L15.2296 13.9991L21.7629 20.5325L20.5334 21.7619L14.0001 15.2286L7.46677 21.7619Z"/>
            </g>
        </svg>
    </ng-template>
    <ng-template pTemplate="content">
        {{idleState}}
    </ng-template>
   <ng-template pTemplate="footer">
    <div class="flex justify-content-between align-items-end align-self-stretch">    
        <p-button id="session-logout" label="Log Out" styleClass="p-button-secondary" (click)="logout()"></p-button>
        <p-button id="session-reset-timer" label="Stay Logged In" (click)="resetTimer()"></p-button>
    </div>
    </ng-template>
</p-dialog>