<div class="header-container">
  <div class="header-top-row">
    <div class="configuration-header-text font-1125 flex flex-row gap-1">
      <ng-container *ngIf="headerLinkLabel && headerRouteInfo">
        <!-- left arrow -->
        <span class="flex flex-row align-items-center configuration-header-link gap-1"
              [routerLink]="headerRouteInfo.commands" [queryParams]="_routeParams">
          <span class="material-symbols-sharp atom-purple no-underline">
            arrow_back
          </span>
          <div >
            {{headerLinkLabel}}
          </div>
        </span>
        / <!-- separator for link label and title -->
      </ng-container>
      <div class="text-overflow-ellipsis overflow-hidden">{{headerTitle}}
      </div>
    </div>
    <div *ngIf="toggleVisible && toggleLabel && toggleEvent" class="configuration-toggle-container">
      <div class="toggle-label-text">
        {{toggleLabel}}
      </div>
      <p-inputSwitch #toggle [(ngModel)]="toggleState" (ngModelChange)="toggleFlip(toggle)"></p-inputSwitch>
    </div>
    <ng-template #defaultHeaderRight></ng-template>
    <ng-container *ngIf="rightHeaderCustom"
                  [ngTemplateOutlet]="rightHeaderTemplate || defaultHeaderRight">

    </ng-container>
  </div>
  <div *ngIf="headerSubtitle" class="header-bottom-row">
    <div class="subtitle-container">
      <div class="subtitle-text">
        {{headerSubtitle}}
      </div>
    </div>
  </div>
</div>
