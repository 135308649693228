<div #siteFeedbackButton id="site-feedback-button" class="flex flex-column p-3 justify-content-center align-items-center cursor-pointer sidenav-button" (click)="toggleSidebar()">
    <p #siteFeedbackText id="site-feedback-text" class="sidenav-text text-sm font-normal">Site&nbsp;Feedback</p>
</div>
<p-sidebar [(visible)]="sidebarVisible" position="right" (onHide)="toggleSidebar()">
    <ng-template pTemplate="closeicon">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <mask id="mask0_1883_6197" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="28">
            <rect width="28" height="28" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1883_6197)">
            <path class="fill" d="M7.46677 21.7619L6.2373 20.5325L12.7706 13.9991L6.2373 7.46579L7.46677 6.23633L14.0001 12.7697L20.5334 6.23633L21.7629 7.46579L15.2296 13.9991L21.7629 20.5325L20.5334 21.7619L14.0001 15.2286L7.46677 21.7619Z" />
            </g>
        </svg>
    </ng-template>
    <ng-template pTemplate="header">
        <h1 class="text-lg font-semibold feedback-header gap-5">Site Feedback</h1>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-column gap-5 align-items-center justify-content-center align-self-stretch p-0" *ngIf="!success && !error">
            <div>
                <p class="text-sm font-normal feedback-content-text">We welcome any feedback you have for the Host Manager Site.</p>
                <p class="text-sm font-normal feedback-content-text">Your feedback helps us identify and prioritize site improvements and new features.</p>
                <p class="text-xs font-normal font-italic feedback-content-text">Have a Support Request? Please <span class="feedback-content-text-color-change cursor-pointer">file a Support Ticket</span>.This feedback is not monitored by our support technicians. We do not respond directly to these submissions.</p>
            </div>
            <div class="w-full">
                <form [formGroup]="form">
                    <textarea #ta [class.overflow-y-auto]="ta.getBoundingClientRect().height >= 450" id="text-area" contenteditable="true" class=" flex text-sm p-2 justify-content-between align-items-start flex-shrink-0 align-self-stretch font-normal w-full" [autoResize]="true" pInputTextarea placeholder="Enter your feedback here" formControlName="feedback"></textarea>
                </form>
                <div class="error-text-container">
                    <small *ngIf="!form.valid && form.dirty" class="overflow-hidden text-overflow-ellipsis text-xs font-normal">Required Field</small>
                </div>
            </div>


            <p-button id="submit-feedback-button" label="Submit Feedback" (click)="submitFeedback()" [disabled]="!form.valid"></p-button>
        </div>
        <atom-widget-error *ngIf="error" [customErrorMessage]="true" [customHeaders]="errorMessageHeader" [customContent]="errorMessageContent"></atom-widget-error>
        <atom-success-notification *ngIf="success" [messageHeader]="successMessageHeader" [messageContent]="successMessageContent"></atom-success-notification>
    </ng-template>
</p-sidebar>
