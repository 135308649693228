import { Component, Input } from '@angular/core';

@Component({
  selector: 'atom-success-notification',
  templateUrl: './success-notification.component.html',
  styleUrls: ['./success-notification.component.scss']
})
export class SuccessNotificationComponent {
  @Input() messageHeader = '';
  @Input() messageContent = '';
}
