import {Component, Input, TemplateRef} from '@angular/core';
import {WidgetState} from "./widget-state";
import {TemplateType} from "@models/table.model";

@Component({
  selector: 'atom-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss']
})
export class WidgetContainerComponent {
  @Input() widgetState!: WidgetState;

  @Input() size!: number;
  @Input() containerStyleClass: string = '';
  @Input() contentStyleClass: string = '';

  @Input() headerText: string = '';
  @Input() keepHeaderOnError: boolean = false;
  @Input() errorHeaderText!: string;
  @Input() infoMessage!: string;

  @Input() customMessage: boolean = false;
  @Input() customMessageHeader!: string[];
  @Input() customMessageContent!: string[];

  @Input() headerTemplate: TemplateRef<any> | undefined;
  @Input() rightHeaderTemplate: TemplateRef<any> | undefined;
  @Input() bodyTemplate: TemplateRef<any> | undefined;
  @Input() footerTemplate: TemplateRef<any> | undefined;
  @Input() disableFooter: boolean = false;

  protected readonly WidgetState = WidgetState;

  protected readonly TemplateType = TemplateType;
}
