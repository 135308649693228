import { Component, Input } from '@angular/core';
import {TitleIconInfo} from "@models/widget.models";

@Component({
  selector: 'atom-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {
  @Input({required: true}) currentStep!: number;
  @Input({required: true}) steps!: TitleIconInfo[];
}
