<div class="widget-error-container mt-4">
  <div class="widget-error-outer-content-container">
    <div class="widget-error-inner-content-container">
      <div class="relative w-4rem h-4rem">
        <svg class="body-alert-outer-badge" xmlns="http://www.w3.org/2000/svg" width="64" height="64"
             viewBox="0 0 64 64"
             fill="none">
          <circle opacity="0.3" cx="32" cy="32" r="32"/>
          <circle cx="32" cy="32" r="24"/>
        </svg>
        <svg class="body-alert-inner-badge" xmlns="http://www.w3.org/2000/svg" width="24"
             height="24" viewBox="0 0 24 25" fill="none">
          <mask class="body-alert-inner-badge" id="mask0_2192_3446" style="mask-type:alpha"
                maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
            <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g class="body-alert-inner-badge" mask="url(#mask0_2192_3446)">
            <path
              d="M1.86523 20.9999L11.9998 3.5L22.1343 20.9999H1.86523ZM4.44978 19.4999H19.5498L11.9998 6.49993L4.44978 19.4999ZM11.9998 18.3076C12.2286 18.3076 12.4205 18.2302 12.5753 18.0754C12.7301 17.9206 12.8075 17.7288 12.8075 17.4999C12.8075 17.2711 12.7301 17.0793 12.5753 16.9245C12.4205 16.7697 12.2286 16.6923 11.9998 16.6923C11.7709 16.6923 11.5791 16.7697 11.4243 16.9245C11.2695 17.0793 11.1921 17.2711 11.1921 17.4999C11.1921 17.7288 11.2695 17.9206 11.4243 18.0754C11.5791 18.2302 11.7709 18.3076 11.9998 18.3076ZM11.2498 15.6923H12.7498V10.6923H11.2498V15.6923Z"
              fill="#B42318"/>
          </g>
        </svg>
      </div>
      <div class="body-error-outer-text-container" *ngIf="!customErrorMessage; else customError">
        <div class="body-error-middle-text-container">
          <div class="body-error-inner-text-container">
            <span class="error-top-text">{{widgetTitle}} Unavailable</span>
            <span class="error-bottom-text">Unable to retrieve {{widgetTitle}}</span>
          </div>
        </div>
      </div>
      <ng-template #customError>
        <div class="body-error-outer-text-container">
          <div class="body-error-middle-text-container">
            <div class="body-error-inner-text-container">
              <ng-container *ngFor="let header of customHeaders">
                <span class="error-top-text">{{header}}</span>
              </ng-container>
              <ng-container *ngFor="let content of customContent">
                <span class="error-bottom-text custom-spacing">{{content}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
