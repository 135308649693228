import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'atom-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  @Input() closable!: boolean;
  @Input({required: true}) severity!: string;

  @Input({required: true}) headerText!: string;
  @Input({required: true}) contentText!: string;
  @Input() headerClass: string = "message-header-14";
  @Input() bodyClass: string = "message-body-14";
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();

  closeClicked() {
    this.closeClick.emit();
  }
}
