<ng-template #customModalStateTemplate let-context="customModalViewContext">
  <div class="modal-content-container flex w-full">
    <div class="modal-container-outer flex flex-column w-full">
      <div class="modal-container-inner flex flex-column align-items-center">
        <div class="modal-svg-container relative">
          <div class="absolute"
               [ngClass]="{
               'good-circle-icon': context.templateType == TemplateType.Good,
               'info-circle-icon': context.templateType == TemplateType.Info,
               'warning-circle-icon': context.templateType == TemplateType.Warning,
               'error-circle-icon': context.templateType == TemplateType.Error,
               }"></div>
          <div class="absolute modal-icon-position"
               [ngClass]="{
               'good-icon': context.templateType == TemplateType.Good,
               'info-icon': context.templateType == TemplateType.Info,
               'warning-icon': context.templateType == TemplateType.Warning,
               'error-icon': context.templateType == TemplateType.Error,
               }"></div>
        </div>
        <div class="modal-text-container flex flex-column align-items-center">
          <ng-container *ngFor="let header of context.customHeaders">
            <span class="modal-header-text ">{{header}}</span>
          </ng-container>
          <ng-container *ngFor="let content of context.customContent">
            <span class="modal-content-text">{{content}}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
