<ng-template #none>
</ng-template>

<ng-template #dateSelector>
  <ng-container *ngIf="rightHeaderVisible">
    <div class="flex flex-row gap-5 right-header-wrap">
      <p-dropdown id="rangeSelector"
                  class="range-dropdown-size"
                  [style]="{width: '100%'}"
                  [scrollHeight]="'290px'"
                  [options]="timeframeOptions"
                  [(ngModel)]="selectedTimeframe"
                  (onChange)="timeframeChange()"
                  placeholder="Select an Energy Profile">
        <ng-template pTemplate="dropdownicon"><span class="material-symbols-sharp">expand_more</span></ng-template>
      </p-dropdown>
      <p-calendar #calendar
                  *ngIf="selectedTimeframe == 'custom'"
                  [(ngModel)]="templateDateRange"
                  class="p-calendar-styling"
                  placeholder="Select Dates"
                  selectionMode="range"
                  [selectOtherMonths]="true"
                  [style]="{'width': '100%', height: '100%'}"
                  [inputStyle]="{'width': '188px'}"
                  [showIcon]="true"
                  [readonlyInput]="true"
                  [maxDate]="maxDate"
                  [disabledDates]="disableDate"
                  (onClose)="selectedDates()"
                  (onSelect)="disableSameDaySelect($event)"
                  panelStyleClass="line-chart-datepicker-position"
                  [appendTo]="'body'">
        <ng-template pTemplate="triggericon"><span class="material-symbols-sharp">expand_more</span></ng-template>
        <ng-template pTemplate="previousicon"><span class="material-symbols-sharp">chevron_left</span></ng-template>
        <ng-template pTemplate="nexticon"><span class="material-symbols-sharp">chevron_right</span></ng-template>
      </p-calendar>
    </div>
  </ng-container>
</ng-template>

<atom-widget-container class="flex w-full "
                       [headerText]="headerText"
                       [widgetState]="chartWidgetState"
                       [disableFooter]="chartWidgetState == WidgetState.Good"
                       [rightHeaderTemplate]="rightHeaderTemplate ? rightHeaderTemplate : dateSelector">
  <div *ngIf="chartWidgetState == WidgetState.Good && initialized && !noData"
       class="line-body-container pb-0 top-padding w-full">
    <highcharts-chart #chart class="w-full" [Highcharts]="highcharts" [(update)]="chartUpdate"
                      [options]="chartOptions" style="z-index: 3; min-width:400px;">
    </highcharts-chart>
  </div>
  <div *ngIf="chartWidgetState == WidgetState.Good && noData" class="no-results-container">
    <ng-container [ngTemplateOutlet]="compRef.instance.customStateTemplate"
                  [ngTemplateOutletContext]="{customStateViewContext: noDataInfoViewContext}"/>
  </div>
</atom-widget-container>
