import {FormGroup } from "@angular/forms";

export interface Form {
}

export enum InputType {
  text,
  dropdown
}

export enum TextFormType {
  text,
  numberCurrency,
  numberWhole,
  numberDecimal,
  dropdown
}

export declare interface AtomForm {
  apInitializeForm(formId?: number): void;
  apSubmitChanges(form?: AtomFormGroup): void;
  apDiscardChanges(form?: AtomFormGroup): void;
}

export declare interface AtomFormInput {

  /**
   * required
   *
   * If true, the input will be marked as required
   */
  required: boolean;

  /**
   * group
   *
   * The FormGroup containing the control to be displayed
   * */
  group: FormGroup;

  /**
   * controlName
   *
   * The name of the control in the group to be displayed
   */
  controlName: string;

  /**
   * fieldName
   *
   * The name of the field to be displayed
   */
   fieldName: string;

   /**
   * editable
   *
   * If true, the input field will be editable. If false, the input field will be replaced with a label.
   * */
   editable: boolean;
}



export interface AtomFormGroup {
    id: number;
  apGroup: FormGroup<any>;
}
