<p-messages [severity]="severity">
  <ng-template pTemplate>
    <div class="ap-toast-container">
      <div class="left-side">
        <div class="icon-container">
          <div class="toast-svg-container">
            <div class="toast-inner-ring" [ngClass]="{
          'success-border': severity === 'success',
          'info-border': severity === 'info',
          'warning-border': severity === 'warn',
          'error-border': severity === 'error'
          }"></div>
            <div class="toast-outer-ring" [ngClass]="{
          'success-border': severity === 'success',
          'info-border': severity === 'info',
          'warning-border': severity === 'warn',
          'error-border': severity === 'error'
          }"></div>
            <div *ngIf="severity === 'success'" class="good-icon good-color toast-svg"></div>
            <div *ngIf="severity === 'info'" class="info-icon info-color toast-svg"></div>
            <div *ngIf="severity === 'warn'" class="warning-icon warning-color toast-svg"></div>
            <div *ngIf="severity === 'error'" class="error-icon error-color toast-svg"></div>
          </div>
        </div>
        <div class="message-content-container">
          <div class="message-header-container">
            <div [ngClass]="headerClass">
              {{ headerText }}
            </div>
          </div>
          <div class="message-body-container">
            <div [ngClass]="bodyClass">
              {{ contentText }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="closable" (click)="closeClicked()"
           class="p-1 flex justify-content-center align-items-center cursor-pointer close-button">
        <span class="material-symbols-sharp">close</span>
      </div>
    </div>
  </ng-template>
</p-messages>

