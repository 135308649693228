<div class="error-container">
  <div class="alert-icon-container">
    <div class="outer-ring"></div>
    <div class="inner-ring"></div>
    <div class="error-icon alert-icon"></div>
  </div>
  <div class="alert-text-container">
    <div class="alert-content">
      <div class="alert-text-header-container">
        <span class="header-text">
          {{headerText}}
        </span>
      </div>
      <div class="alert-text-content-container">
        <span class="content-text">
          {{contentText}}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="closable" (click)="closeClicked()" class="p-1 flex justify-content-center align-items-center cursor-pointer close-button">
    <span class="material-symbols-sharp">close</span>
  </div>
</div>
