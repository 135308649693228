<p-toast [preventOpenDuplicates]="true">
  <ng-template let-message pTemplate="message">
    <div class="ap-toast-container">
      <div class="icon-container">
        <div class="toast-svg-container">
          <div class="toast-inner-ring" [ngClass]="{
          'success-border': message.severity === 'success',
          'info-border': message.severity === 'info',
          'warning-border': message.severity === 'warn',
          'error-border': message.severity === 'error'
          }"></div>
          <div class="toast-outer-ring" [ngClass]="{
          'success-border': message.severity === 'success',
          'info-border': message.severity === 'info',
          'warning-border': message.severity === 'warn',
          'error-border': message.severity === 'error'
          }"></div>
          <svg *ngIf="message.severity === 'success'" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"
               class="toast-svg">
            <mask id="mask0_5676_6895" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                  height="25">
              <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_5676_6895)">
              <path
                d="M10.5808 16.7538L17.3038 10.0308L16.25 8.97693L10.5808 14.6462L7.73075 11.7962L6.67693 12.85L10.5808 16.7538ZM12.0016 22C10.6877 22 9.45268 21.7506 8.29655 21.252C7.1404 20.7533 6.13472 20.0765 5.2795 19.2217C4.42427 18.3669 3.74721 17.3616 3.24833 16.206C2.74944 15.0504 2.5 13.8156 2.5 12.5017C2.5 11.1877 2.74933 9.95268 3.248 8.79655C3.74667 7.6404 4.42342 6.63472 5.27825 5.7795C6.1331 4.92427 7.13834 4.24721 8.29398 3.74833C9.44959 3.24944 10.6844 3 11.9983 3C13.3122 3 14.5473 3.24933 15.7034 3.748C16.8596 4.24667 17.8652 4.92342 18.7205 5.77825C19.5757 6.6331 20.2527 7.63834 20.7516 8.79398C21.2505 9.94959 21.5 11.1844 21.5 12.4983C21.5 13.8122 21.2506 15.0473 20.752 16.2034C20.2533 17.3596 19.5765 18.3652 18.7217 19.2205C17.8669 20.0757 16.8616 20.7527 15.706 21.2516C14.5504 21.7505 13.3156 22 12.0016 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2666 19.225 8.37498 17.675 6.82498C16.125 5.27498 14.2333 4.49998 12 4.49998C9.76664 4.49998 7.87498 5.27498 6.32498 6.82498C4.77498 8.37498 3.99998 10.2666 3.99998 12.5C3.99998 14.7333 4.77498 16.625 6.32498 18.175C7.87498 19.725 9.76664 20.5 12 20.5Z"
                fill="#067647"/>
            </g>
          </svg>
          <svg *ngIf="message.severity === 'info'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="toast-svg">
            <mask id="mask0_4019_1904" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_4019_1904)">
              <path d="M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z" fill="#490089"/>
            </g>
          </svg>
          <svg *ngIf="message.severity === 'warn'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="toast-svg">
            <mask id="mask0_1764_22585" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1764_22585)">
              <path d="M12 16.7307C12.2288 16.7307 12.4207 16.6533 12.5755 16.4985C12.7303 16.3437 12.8077 16.1519 12.8077 15.9231C12.8077 15.6942 12.7303 15.5024 12.5755 15.3476C12.4207 15.1928 12.2288 15.1154 12 15.1154C11.7711 15.1154 11.5793 15.1928 11.4245 15.3476C11.2697 15.5024 11.1923 15.6942 11.1923 15.9231C11.1923 16.1519 11.2697 16.3437 11.4245 16.4985C11.5793 16.6533 11.7711 16.7307 12 16.7307ZM11.25 13.0769H12.75V7.0769H11.25V13.0769ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z" fill="#B54708"/>
            </g>
          </svg>
          <svg *ngIf="message.severity === 'error'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="toast-svg">
            <mask id="mask0_1764_22581" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1764_22581)">
              <path d="M1.86523 20.4999L11.9998 3L22.1343 20.4999H1.86523ZM4.44978 18.9999H19.5498L11.9998 5.99993L4.44978 18.9999ZM11.9998 17.8076C12.2286 17.8076 12.4205 17.7302 12.5753 17.5754C12.7301 17.4206 12.8075 17.2288 12.8075 16.9999C12.8075 16.7711 12.7301 16.5793 12.5753 16.4245C12.4205 16.2697 12.2286 16.1923 11.9998 16.1923C11.7709 16.1923 11.5791 16.2697 11.4243 16.4245C11.2695 16.5793 11.1921 16.7711 11.1921 16.9999C11.1921 17.2288 11.2695 17.4206 11.4243 17.5754C11.5791 17.7302 11.7709 17.8076 11.9998 17.8076ZM11.2498 15.1923H12.7498V10.1923H11.2498V15.1923Z" fill="#B42318"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="message-content-container">
        <div class="message-header-container">
          <div class="message-header">
            {{message.summary}}
          </div>
        </div>
        <div class="message-body-container">
          <div class="message-body">
            {{message.detail}}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
