<div class="flex flex-row">
    <div class="flex flex-column align-items-start custom-icon cursor-pointer" [routerLink]="dashboardLink">
        <span class="material-symbols-sharp position-adjust">dashboard</span>
    </div>
    <div class="flex flex-column align-items-start custom-icon cursor-pointer" [routerLink]="analyticLink">
        <span class="material-symbols-sharp position-adjust">finance</span>
    </div>
    <div class="flex flex-column align-items-start custom-icon cursor-pointer" *appFeatureControl="['OrgAdmin', 'SiteAdmin', 'SiteViewer'],type:'remove';id:siteData.siteId" [routerLink]="supportLink">
        <span class="material-symbols-sharp position-adjust">support</span>
      <!-- TODO: enable when notifications are implemented -->
        <!--<div class="flex flex-column justify-content-start align-items-end relative flex-shrink-0 left-icon-notification left-icon-notification-unselected"></div>-->
    </div>
    <div class="flex flex-column align-items-start custom-icon cursor-pointer" *appFeatureControl="['OrgAdmin', 'SiteAdmin'],type:'remove';id:siteData.siteId" [routerLink]="siteDetailsLink">
        <span class="material-symbols-sharp position-adjust">settings</span>
    </div>
  <!-- TODO: enable when notifications are implemented -->
<!--    <div class="flex flex-column align-items-start custom-icon cursor-pointer" *appFeatureControl="['OrgAdmin', 'SiteAdmin', 'SiteViewer'],type:'remove';id:siteData.siteId" [routerLink]="notificationLink">
        <span class="material-symbols-sharp position-adjust">notifications</span>
        <div class="flex flex-column justify-content-start align-items-end relative flex-shrink-0 left-icon-notification left-icon-notification-unselected"></div>
        &lt;!&ndash; *ngIf="notificationCount > 0"
        [ngClass]="{
       'left-icon-notification-selected': notificationMenuItem.selected,
       'left-icon-notification-unselected': !notificationMenuItem.selected
       }"></div> &ndash;&gt;
    </div>-->
</div>
