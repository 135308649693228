import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from "@azure/msal-angular";
import { VerifyEmailRequest } from './verify-email.model';
import {Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserCredential } from '@global/models/user-data.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,
              private auth: MsalService,
              private http: HttpClient) {
  }

  async navToLogin(): Promise<void> {
    await this.router.navigateByUrl('core');
  }

  async logout() {
    sessionStorage.clear();
    await this.auth.logout();
  }

  getPermissions(): Observable<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/auth/entrypoint`)
  }

  /**
   * @apiRouteName verify-email
   * @param data
   * @returns
   */
  verifyUserEmail(data: VerifyEmailRequest): Promise<void> {
    return lastValueFrom(this.http.post<void>(`${environment.apiEndpoint}/verify-email`, data));
  }

  /**
   * @apiRouteName verify-driver-email
   * @param data
   * @returns
   */
  verifyDriverEmail(data: VerifyEmailRequest): Promise<void> {
    return lastValueFrom(this.http.post<void>(`${environment.apiEndpoint}/verify-driver-email`, data));
  }

  searchLocalSessionKeys(substring: string): string[] {
    const matchingKeys: string[] = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.includes(substring)) {
        matchingKeys.push(key);
      }
    }
    return matchingKeys;
  }

  getEvseApiAccessToken(): UserCredential | null {
    try {
      const key: string = environment.keyForEvseApiAccessToken;

      const protectedResourceMap = environment.azureB2C.interceptorConfig.protectedResourceMap;
      const firstResource = protectedResourceMap.get(key);

      if (!firstResource || firstResource.length === 0) {
        return null;
      }

      const prm = firstResource[0].toString().toLowerCase();
      const accessTokenList = this.searchLocalSessionKeys(prm);

      if (accessTokenList.length > 0) {
        const at = sessionStorage.getItem(accessTokenList[0]);
        if (at) {
          return JSON.parse(at) as UserCredential;
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }
}
