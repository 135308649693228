import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
@Component({
  selector: 'atom-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  sidebarVisible = false;
  value: string = '';
  success = false;
  error = false;
  errorMessageHeader = ['System Error!'];
  successMessageHeader = 'Thank You!';
  errorMessageContent = ['Due to system error your \n feedback was not submitted. \n Please try again later.'];
  successMessageContent = 'Your feedback has been \n successfully submitted.';
  form!: FormGroup;
  @ViewChild('siteFeedbackButton', { static: false }) feedbackBtn!: ElementRef;
  @ViewChild('siteFeedbackText', { static: false }) feedbackTxt!: ElementRef;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      feedback: [null, Validators.required],
    })
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
    this.clearForm();
    if (this.sidebarVisible) {
      this.feedbackBtn.nativeElement.classList.add("sidenav-button-add-on");
      this.feedbackTxt.nativeElement.classList.add("sidenav-text-add-on");
    } else {
      this.feedbackBtn.nativeElement.classList.remove("sidenav-button-add-on");
      this.feedbackTxt.nativeElement.classList.remove("sidenav-text-add-on");
      this.success = false;
      this.error = false;
      this.value = '';
    }

  }

  submitFeedback() {
    if (this.form.valid) {
      // based on call toggle success or error variable
      this.success = true;
      // Uncomment this line when there is an error
      // this.error = true;
      this.clearForm();
    } else {
      return
    }
  }

  clearForm() {
    this.form.reset();
  }
}
