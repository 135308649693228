import { InteractionType, LogLevel } from "@azure/msal-browser";
import { ProtectedResourceScopes } from "@azure/msal-angular";

const resourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
resourceMap.set('https://dev-api-01.atompower.com/ev/v1/verify-email', null); // For routes that should not require authentication but share the baseUrl
resourceMap.set('https://dev-api-01.atompower.com/ev/v1/verify-driver-email', null);
resourceMap.set('https://dev-api-01.atompower.com/*', ['https://atompowerdev.onmicrosoft.com/de76ec9c-eb58-420b-ba0a-5af49cb9af59/Host.User']);

export const environment = {
  production: false,
  apiEndpoint: 'https://dev-api-01.atompower.com/ev/v1',
  driverAppUrl:'https://dev-drive.atompower.com/',
  zendeskAppUrl: 'https://atompower.zendesk.com',
  requireHttps: true,
  showDebugInformation: false,
  protocol: 'https',
  websocketProtocol: 'wss',
  idleTime: 3600,
  countdownTime: 120,
  azureB2C: {
    auth: {
      clientId: "5a07308c-17df-4eb4-8b96-570ce197b9b7",
      authority: "https://atompowerdev.b2clogin.com/atompowerdev.onmicrosoft.com/B2C_1_signin",
      redirectUri: "/core",
      knownAuthorities: ['atompowerdev.b2clogin.com'],
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
    guardConfig: {
      interactionType: InteractionType.Redirect,
      loginFailedRoute: '/'
    },
    interceptorConfig: {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: resourceMap
    }
  }
};
