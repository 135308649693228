import {Component, Input} from '@angular/core';

@Component({
  selector: 'atom-config-input-header',
  templateUrl: './config-input-header.component.html',
  styleUrls: ['./config-input-header.component.scss']
})
export class ConfigInputHeaderComponent {

  @Input() required: boolean = false;

  @Input() fieldCounter: boolean = false;
  @Input() currLen: number | undefined;
  @Input() maxLen!: number;

  @Input() headerText!: string;
}
