import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WidgetState} from "@core-shared/components/widget-container/widget-state";
import { PieChartData } from '@core-shared/models/chart.models';
import * as Highcharts from "highcharts";

@Component({
  selector: 'atom-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges{

  @Input() headerText!: string;
  @Input() pieChartState!: WidgetState;

  @Input() infoMessage!: string;

  @Input() pieData!: PieChartData[];
  @Input() unit = '';
  totalCount: number = 0;

  highcharts: typeof Highcharts = Highcharts;

  chartOptions!: Highcharts.Options
  chartUpdate: boolean = false;

  ngOnInit(): void {
    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: 0,
        plotShadow: false,
        margin: 0,
        backgroundColor: undefined,
      },
      title: {
        text: undefined
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1
            }
          }
        },
        pie: {
          zIndex: 2,
          animation: true,
          dataLabels: {
            enabled: false
          }
        }
      },
      xAxis: {
        crosshair: false
      },
      tooltip: {
        positioner: (labelWidth, labelHeight, point: Highcharts.TooltipPositionerPointObject) => {
          let q = this.findGraphQuadrant(point.plotX, point.plotY)
          let x = 0
          let y = 0

          switch (q) {
            case 1:
              x = point.plotX - (labelWidth);
              y = point.plotY - (labelHeight);
              break;
            case 2:
              x = point.plotX;
              y = point.plotY - (labelHeight);
              break;
            case 3:
              x = point.plotX - (labelWidth);
              y = point.plotY;
              break;
            case 4:
              x = point.plotX;
              y = point.plotY;
              break;
          }

          return {x, y}
        },
        shape: "square",
        animation: false,
        outside: true,
        useHTML: true,
        backgroundColor: "var(--map-popup-color,#ffffff)",
        formatter: function (a) {
        if (this.y && this.total) {
          return '<div><span style="color:  ' + this.color + '">\u25CF &nbsp</span>' + this.key + '</div>' +
            '<div style="margin-top: 10px"><span>' + this.y + ' Sessions</span></div> ' +
            '<div style="margin-top: 10px"><span>'+ Math.floor((this.y / this.total) * 100) + '%  of Charge Triggers</span></div>'

        } else {
          return 'error'
        }
        },

        style: {
          color: "#ffffff",
          fontWeight: "normal",
          fontFamily: "Inter",
          fontSize: ".85rem"
        }
      },
      series: [{
        type: 'pie',
        enableMouseTracking: true,
        borderRadius: 0,
        borderWidth: 0,
        zIndex: 3,
        stickyTracking: false,
        tooltip: {
          followPointer: false,
        },
        data: this.pieData
      }]
    };

    if (this.pieData) {
      this.totalCount = 0;
      this.pieData.forEach((data) => {
        this.totalCount += data.y;
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chartOptions?.series) {
      this.chartOptions.series[0] = {
        type: 'pie',
        data: this.pieData
      }
    }

    this.totalCount = 0;
    this.pieData.forEach((data) => {
      this.totalCount += data.y;
    })

    this.chartUpdate = true;

  }

  findGraphQuadrant(x: number, y: number): number {
    let quadrant = 0;

    if (y >= 75) {
      quadrant = 3;
    } else {
      quadrant = 1;
    }

    if (x >= 75) {
      quadrant = quadrant + 1
    }

    return quadrant
  }
}
