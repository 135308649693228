import { Component } from '@angular/core';

@Component({
  selector: 'atom-page-content-container',
  templateUrl: './page-content-container.component.html',
  styleUrls: ['./page-content-container.component.scss']
})
export class PageContentContainerComponent {

}
