
<atom-widget-container
  [widgetState]="pieChartState"
  [headerText]="headerText"
  [infoMessage]="infoMessage"
  [contentStyleClass]="'gap-4 min-h-0'"
  [disableFooter]="pieChartState === 1">
  <div class="flex justify-content-center align-items-center align-self-stretch gap-4">
    <div class="relative justify-content-center align-items-center z-3 pi-container">
      <div class="border-container">
        <svg class="svg-border" width="189" height="189" viewBox="0 0 204 203" fill="none"
             xmlns="http://www.w3.org/2000/svg">
             <circle cx="101.5" cy="101.5" r="91" stroke-width="15" />
        </svg>
      </div>
      <highcharts-chart #chart [Highcharts]="highcharts" [(update)]="chartUpdate" [options]="chartOptions"
                        style="z-index: 3; display: flex; position: absolute; width: 11.79406rem; height: 11.79406rem;">

      </highcharts-chart>
    </div>
    <div class="flex flex-column align-items-start gap-2">
      <ng-container *ngFor="let data of pieData; let i = index">
          <div *ngIf="data.y > 0" class="flex align-items-center gap-2 align-self-stretch legend-item-container">
              <svg class="legend-color-key" xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                  viewBox="0 0 13 13" fill="none">
                  <circle cx="6.5" cy="6.5" r="6" [attr.fill]="data.color" />
              </svg>
              <span class="text-xs w-12rem font-normal legend-text-key legend-text text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  ({{((data.y / totalCount) * 100).toFixed(0)}}{{unit}}) {{data.name}}
              </span>
          </div>
      </ng-container>
    </div>
  </div>
</atom-widget-container>
