import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from "@azure/msal-angular";
import { VerifyEmailRequest } from './verify-email.model';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,
              private auth: MsalService,
              private http: HttpClient) {
  }

  async navToLogin(): Promise<void> {
    await this.router.navigateByUrl('core');
  }

  async logout() {
    sessionStorage.clear();
    await this.auth.logout();
  }

  /**
   * @apiRouteName verify-email
   * @param data
   * @returns
   */
    verifyUserEmail(data: VerifyEmailRequest): Promise<void> {
      return lastValueFrom(this.http.post<void>(`${environment.apiEndpoint}/verify-email`, data));
    }
  /**
   * @apiRouteName verify-driver-email
   * @param data
   * @returns
   */
    verifyDriverEmail(data: VerifyEmailRequest): Promise<void>{
      return lastValueFrom(this.http.post<void>(`${environment.apiEndpoint}/verify-driver-email`, data));
    }
}
