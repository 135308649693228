import {Component, TemplateRef, ViewChild} from '@angular/core';
import { TemplateType } from '@core-shared/models/table.model';

@Component({
  selector: 'atom-modal-templates',
  templateUrl: './modal-templates.component.html',
  styleUrls: ['./modal-templates.component.scss']
})
export class ModalTemplatesComponent {
  @ViewChild('customModalStateTemplate') customModalStateTemplate!: TemplateRef<any>;

  protected readonly TemplateType = TemplateType;
}
