import {Injectable} from '@angular/core';
import * as Highcharts from "highcharts";
import {DateRange} from "@models/analytics.model";

export enum LineChartTemplate {
  None = -1,
  DateSelector = 1,
}

export const hoursInAnX = {
  HOURS_IN_1_DAY: 24,
  HOURS_IN_1_WEEK: 168,
  HOURS_IN_1_MONTH: 730,
  HOURS_IN_3_MONTHS: 2190,
  HOURS_IN_1_YEAR: 8760
}

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor() {}

  GetXAxisLabelFormat(dateRange: DateRange): Highcharts.AxisLabelsFormatterCallbackFunction {
    let diff = 0;

    if (dateRange) {
      diff = (dateRange.endDate.getTime() - dateRange.startDate.getTime()) / 3600000;
    }

    let xAxisFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = function () {
      return Highcharts.dateFormat('%l %P', +this.value);
    };

    if (diff > hoursInAnX.HOURS_IN_1_WEEK) {
      xAxisFormatter = function () {
        return Highcharts.dateFormat('%m/%d/%Y', +this.value);
      };
    } else if (diff > hoursInAnX.HOURS_IN_1_DAY) {
      xAxisFormatter = function () {
        return Highcharts.dateFormat('%m/%d/%Y<br>%l:%M %P', +this.value);
      };
    }

    return xAxisFormatter
  }

  SevenXAxisPositioner(): Highcharts.AxisTickPositionerCallbackFunction {
    const xAxisPositioner: Highcharts.AxisTickPositionerCallbackFunction = function () {
      if (this.min != null && this.max != null) {
        let tick = Math.floor(this.min);
        const positions = [],
          increment = Math.ceil((this.max - this.min) / 6);

        for (tick; tick - increment <= this.max; tick += increment) {
          positions.push(tick);
        }

        return positions.slice(0, 7);
      }
      return [];
    }

    return xAxisPositioner;
  }

  FourXAxisPositioner(): Highcharts.AxisTickPositionerCallbackFunction {
    const xAxisPositioner: Highcharts.AxisTickPositionerCallbackFunction = function () {
      if (this.min != null && this.max != null) {
        let tick = Math.floor(this.min);
        const positions = [],
          increment = Math.ceil((this.max - this.min) / 3);

        for (tick; tick - increment <= this.max; tick += increment) {
          positions.push(tick);
        }

        return positions.slice(0, 4);
      }
      return [];
    }

    return xAxisPositioner;
  }
}
