import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public messageService: MessageService) { }

  showSuccess(summary: string, detail: string, lifeTime?: number) {
    this.messageService.add({
      life: lifeTime,
      severity: 'success',
      summary: summary,
      detail: detail
    });
  }

  showInfo(summary: string, detail: string, lifeTime?: number) {
    this.messageService.add({
      life: lifeTime,
      severity: 'info',
      summary: summary,
      detail: detail
    });
  }

  showWarn(summary: string, detail: string, lifeTime?: number) {
    this.messageService.add({
      life: lifeTime ? lifeTime : 3000,
      severity: 'warn',
      summary: summary,
      detail: detail
    });
  }

  showError(summary: string, detail: string, lifeTime?: number) {
    this.messageService.add({
      life: lifeTime ? lifeTime : 3000,
      severity: 'error',
      summary: summary,
      detail: detail
    });
  }

  showConfirm(message: string, lifeTime?: number) {
    this.messageService.clear();
    this.messageService.add({
      life: lifeTime ? lifeTime : 3000,
      key: 'confirm',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure?',
      detail: message
    });
  }

  clearMessage(key: string) {
    this.messageService.clear(key);
  }

  onReject() {
    this.messageService.clear();
  }
}
