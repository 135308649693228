<ng-template #warningIcon>
  <div class="relative w-4rem h-4rem">
    <div class="warning-circle-icon"></div>
    <div class="warning-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #infoIcon>
  <div class="relative w-4rem h-4rem">
    <div class="info-circle-icon"></div>
    <div class="info-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #errorIcon>
  <div class="relative w-4rem h-4rem">
    <div class="error-circle-icon"></div>
    <div class="error-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #goodIcon>
  <div class="relative w-4rem h-4rem">
    <div class="good-circle-icon"></div>
    <div class="good-icon absolute body-alert-inner-badge"></div>
  </div>
</ng-template>

<ng-template #customTableStateTemplate let-context="customTableViewContext">
  <tr>
    <td colspan="1000" rowspan="0">
      <div class="flex flex-column justify-content-center align-items-center gap-4"
           [ngClass]="{
            'tb-padding-120': !context.padding || context.padding === SupportedPadding.Padding120,
            'tb-padding-90': context.padding === SupportedPadding.Padding90
            }">
        <ng-container [ngSwitch]="context.templateType">
          <ng-container *ngSwitchCase="TemplateType.Good" [ngTemplateOutlet]="goodIcon"/>
          <ng-container *ngSwitchCase="TemplateType.Info" [ngTemplateOutlet]="infoIcon"/>
          <ng-container *ngSwitchCase="TemplateType.Warning" [ngTemplateOutlet]="warningIcon"/>
          <ng-container *ngSwitchCase="TemplateType.Error" [ngTemplateOutlet]="errorIcon"/>
        </ng-container>
        <div class="flex flex-column align-items-center align-self-stretch body-outer-text-container gap-6">
          <div class="flex flex-column align-items-center align-self-stretch body-middle-text-container gap-4">
            <div class="flex flex-column align-items-center align-self-stretch body-inner-text-container gap-1">
              <span
                class="align-self-stretch text-center text-base font-semibold primary-text-color">{{context.customHeader}}</span>
              <div class="flex flex-column">
                <ng-container *ngFor="let content of context.customContent">
            <span
              class="align-self-stretch text-center text-sm font-normal bottom-text custom-spacing secondary-text-color">{{content}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #customStateTemplate let-context="customStateViewContext">
  <div class="flex flex-column justify-content-center align-items-center gap-4 w-full"
       [ngClass]="{
            'tb-padding-120': !context.padding || context.padding === SupportedPadding.Padding120,
            'tb-padding-90': context.padding === SupportedPadding.Padding90
            }">
    <ng-container [ngSwitch]="context.templateType">
      <ng-container *ngSwitchCase="TemplateType.Good" [ngTemplateOutlet]="goodIcon"/>
      <ng-container *ngSwitchCase="TemplateType.Info" [ngTemplateOutlet]="infoIcon"/>
      <ng-container *ngSwitchCase="TemplateType.Warning" [ngTemplateOutlet]="warningIcon"/>
      <ng-container *ngSwitchCase="TemplateType.Error" [ngTemplateOutlet]="errorIcon"/>
    </ng-container>
    <div class="flex flex-column align-items-center align-self-stretch body-outer-text-container gap-6">
      <div class="flex flex-column align-items-center align-self-stretch body-middle-text-container gap-4">
        <div class="flex flex-column align-items-center align-self-stretch body-inner-text-container gap-1">
              <span
                class="align-self-stretch text-center text-base font-semibold primary-text-color">{{context.customHeader}}</span>
          <div class="flex flex-column">
            <ng-container *ngFor="let content of context.customContent">
            <span
              class="align-self-stretch text-center text-sm font-normal bottom-text custom-spacing secondary-text-color">{{content}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="context.button">
      <div class="flex flex-column align-items-center align-self-stretch">
        <p-button  (onClick)="context.button.action()" class="outline-button">
          <span class="material-symbols-sharp">{{ context.button.icon }}</span>
          <span class="text-sm tab-text lg:flex">{{ context.button.label }}</span>
        </p-button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #sortIcon let-order="order">
  <div class="inline-block">
    <span class="flex justify-content-center align-items-center">
      <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_3006_50541" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14"
                    height="21">
          <rect width="14" height="21" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_3006_50541)">
          <path [ngClass]="{
                       'inactive': order === 1,
                      'base-color': order < 1,
                 }"
                d="M6.91161 17.3311L7 17.4195L7.08839 17.3311L10.0884 14.3312L10.1768 14.2428L10.0884 14.1544L9.52921 13.5952L9.44083 13.5068L9.35244 13.5952L7 15.9476L4.64756 13.5952L4.55917 13.5068L4.47079 13.5952L3.91161 14.1544L3.82322 14.2428L3.91161 14.3312L6.91161 17.3311Z"
                stroke-width="0.25"/>
          <path [ngClass]="{
                  'base-color': order > -1,
                  'inactive': order === -1,
                  }"
                d="M7.07955 4.91988L7 4.84033L6.92045 4.91988L3.92045 7.91987L3.8409 7.99942L3.92045 8.07897L4.47963 8.63814L4.55918 8.71769L4.63872 8.63814L7 6.27687L9.36128 8.63814L9.44083 8.71769L9.52038 8.63814L10.0795 8.07897L10.1591 7.99942L10.0795 7.91987L7.07955 4.91988Z"
                stroke-width="0.25"/>
        </g>
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #paginatorNext>
    <span class="material-symbols-sharp flex justify-content-center h-full svg-fill">
      arrow_forward
    </span>
</ng-template>

<ng-template #paginatorPrevious>
    <span class="material-symbols-sharp flex justify-content-center h-full svg-fill">
      arrow_back
    </span>
</ng-template>
