import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'energyUsage'
})
export class EnergyUsagePipe implements PipeTransform {
    transform(value: number): string {
        let convertedValue = '';

        if (value >= 10_000_000) { // 10 GWh or more
            // We reduce the value by a factor of 10_000 to get the GWh value with 2 decimal place precision as a whole number
            // We then round the value to the nearest whole number and divide by 100 to get the GWh value with 2 decimal place precision

            // e.g. 10_123_456 GWh => 10_123_456 / 10_000 = 1_012.3456 => Round(1_012.3456) = 1012 => 1_012 / 100 = 10.12
            convertedValue = (Math.round(value / 10_000) / 100).toString() + ' GWh';
        } else if (value >= 10_000) { // 10 MWh or more
            // We reduce the value by a factor of 10 to get the MWh value with 2 decimal place precision as a whole number
            // We then round the value to the nearest whole number and divide by 100 to get the MWh value with 2 decimal place precision

            // e.g. 10_123 MWh => 10_123 / 10 = 1_012.3 => Round(1_012.3) = 1012 => 1_012 / 100 = 10.12
            convertedValue = (Math.round(value / 10) / 100).toString() + ' MWh';
        } else if (value >= 1000) { // 1 MWh or more
            // Here we are adding a decimal place to the value to retain one decimal place precision
            // We then round the value to the nearest whole number and divide by 10 to get the kWh value with 1 decimal place precision

            // e.g. 1_234.56789 kWh => 1_234.56789 * 10 = 12_345.6789 => Round(12_345.6789) = 12_345 => 12_345 / 10 = 1_234.5
            convertedValue = (Math.round(value * 10) / 10).toString() + ' kWh';
        } else { // Less than 1 MWh
            // Here we are multiplying by 100 to retain 2 decimal place precision
            // We then round the value to the nearest whole number and divide by 100 to get the kWh value with 2 decimal place precision

            // e.g. 123.456789 kWh => 123.456789 * 100 = 12_345.6789 => Round(12_345.6789) = 12_345 => 12_345 / 100 = 123.45
            convertedValue = (Math.round(value * 100) / 100).toString() + ' kWh';
        }

        return convertedValue;
    }
}
