import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'atom-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  @Input() siteData: any;

  dashboardLink: string = '';
  analyticLink: string = '';
  supportLink: string = '/core/support';
  siteDetailsLink: string = '';
  notificationLink: string = '/core/notifications';

  constructor() {}

  ngOnInit(): void {
    this.dashboardLink = '/core/organizations/' + this.siteData.organizationId + '/sites/' + this.siteData.siteId + '/dashboard';
    this.analyticLink  = '/core/organizations/' + this.siteData.organizationId + '/sites/' + this.siteData.siteId + '/analytics';
    this.siteDetailsLink = '/core/organizations/' + this.siteData.organizationId + '/sites/' + this.siteData.siteId + '/config/site-details';
  }
}
